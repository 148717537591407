import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink, Link, useNavigate } from 'react-router-dom'

import { StyledTooltip } from '../StyledToolTip/ToolTip'

import { ArrowRight } from '@mui/icons-material'
import { IconButton, MenuItem } from '@mui/material'
import { Squash as Hamburger } from 'hamburger-react'
import { ClickAwayListener } from '@mui/material';
// import { styled } from '@mui/material/styles';

import Lang from '../../assets/navbar/Lang.svg'
import LangLight from '../../assets/navbar/Lang_light.svg'
import Logo from '../../assets/navbar/LUGAR.svg'
import Compass from '../../assets/navbar/Compass.svg'
import Lupa from '../../assets/navbar/Lupa.svg'
import LupaLight from '../../assets/navbar/Lupa_light.svg'
import Border from '../../assets/navbar/menu_border.svg'
import UK from '../../assets/navbar/uk.svg'
import PT from '../../assets/navbar/pt.svg'

import './Navbar.css'

function Navbar() {
    const [click, setClick] = useState(false);
    const [displaySearch, setDisplaySearch] = useState(false)
    const [displayLanguage, setDisplayLanguage] = useState(false)
    const [searchData, setSearchData] = useState('')
    const [t, i18n] = useTranslation('translation')
    const inputRef = useRef(null)

    let navigate = useNavigate();

    const changeLanguage = (language) => {
        setDisplayLanguage(false)
        i18n.changeLanguage(language.target.id)
    }

    const searchInput = (event) => {
        event.preventDefault();
        if (new RegExp(/\W/gm).test(event.target.value)) {
            return;
        }
        setSearchData(event.target.value)
    }

    const NavigateToSearch = () => {
        if (searchData.length > 0) {
            setSearchData('')
            navigate(`search/${searchData}`)
            setDisplaySearch(false);
        }
    }

    const handleSearchClick = () => {
        inputRef.current.focus()
        setDisplaySearch(true);
    }

    return (
        <div>
            <nav className='navbar'>
                <div className='navbar-logo'>
                    <NavLink to='/' style={{ width: '100%' }} onClick={() => setClick(!click)}>
                        <img alt='logo' src={Logo} className='logo' />
                    </NavLink>
                </div>
                <div className={click ? 'navbar-container-clicked' : 'navbar-container'}>
                    <div className={click ? 'menu-icon-clicked' : 'menu-icon'}>
                        <Hamburger toggled={click} toggle={setClick} size={26} rounded color={click ? 'white' : 'black'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='navbar-itens'>
                            <NavLink to='/sobre' state={{ y: 0 }} className={({ isActive }) => isActive ? 'navbar-item-active' : 'navbar-item'} onClick={() => setClick(!click)}>
                                <p>{t('navbar.buttons.about')}</p>
                                <img alt='border' src={Border} width='140px' className='navbar-item-border' />
                            </NavLink>
                            <div className='about-black-div'>
                                <Link to={'/sobre'} state={{ y: 0 }} style={{ lineHeight: '35px' }}>LUGAR</Link>
                                <Link to={'/sobre'} state={{ y: 1100 }} style={{ lineHeight: '16px' }}>AS ALDEIAS DE MONTANHA</Link>
                                <Link to={'/sobre'} state={{ y: 2000 }} style={{ lineHeight: '35px' }}>ADIRAM</Link>
                            </div>
                        </li>
                        <li className='navbar-itens'>
                            <NavLink to='/projetos' className={({ isActive }) => isActive ? 'navbar-item-active' : 'navbar-item'} onClick={() => setClick(!click)}>
                                <p>{t('navbar.buttons.projects')}</p>
                                <img alt='border' src={Border} width='140px' className='navbar-item-border' />
                            </NavLink>
                        </li>
                        <li className='navbar-itens'>
                            <NavLink to='/resultados' className={({ isActive }) => isActive ? 'navbar-item-active' : 'navbar-item'} onClick={() => setClick(!click)}>
                                <p>{t('navbar.buttons.results')}</p>
                                <img alt='border' src={Border} width='140px' className='navbar-item-border' />
                            </NavLink>
                        </li>
                        <li className='navbar-itens'>
                            <NavLink to='/noticias' className={({ isActive }) => isActive ? 'navbar-item-active' : 'navbar-item'} onClick={() => setClick(!click)}>
                                <p>{t('navbar.buttons.news')}</p>
                                <img alt='border' src={Border} width='140px' className='navbar-item-border' />
                            </NavLink>
                        </li>
                        <li className='navbar-itens'>
                            <NavLink to='/contactos' className={({ isActive }) => isActive ? 'navbar-item-active' : 'navbar-item'} onClick={() => setClick(!click)}>
                                <p>{t('navbar.buttons.contacts')}</p>
                                <img alt='border' src={Border} width='140px' className='navbar-item-border' />
                            </NavLink>
                        </li>
                        <li className='navbar-button-itens'>
                            <StyledTooltip title={t('navbar.tooltips.map')}>
                                <NavLink to='/mapa' className={({ isActive }) => isActive ? 'navbar-button-item-active' : 'navbar-button-item'} onClick={() => setClick(!click)}>
                                    <IconButton className='navbar-button-compass'>
                                        <img alt='compass' src={Compass} className='navbar-button-img' />
                                    </IconButton>
                                </NavLink>
                            </StyledTooltip>
                            <StyledTooltip title={t('navbar.tooltips.search')}>
                                <IconButton className='navbar-button-lupa' onClick={handleSearchClick}>
                                    <img alt='search' src={Lupa} className='navbar-button-img' />
                                </IconButton>
                            </StyledTooltip>
                            <StyledTooltip title={t('navbar.tooltips.lang')}>
                                <IconButton className='navbar-button-lupa' onClick={() => setDisplayLanguage(true)}>
                                    <img alt='Language' src={Lang} className='navbar-button-img' />
                                </IconButton>
                            </StyledTooltip>
                        </li>
                    </ul>
                    <div className={click ? 'navbar-button-itens-mobile-active' : 'navbar-button-itens-mobile'}>
                        <NavLink to='/mapa' className={({ isActive }) => isActive ? 'navbar-button-item-active' : 'navbar-button-item'} onClick={() => setClick(!click)}>
                            <IconButton className='navbar-button-compass'>
                                <img alt='compass' src={Compass} className='navbar-button-img' />
                            </IconButton>
                        </NavLink>
                        <IconButton className='navbar-button-lupa' onClick={handleSearchClick}>
                            <img alt='search' src={Lupa} className='navbar-button-img' />
                        </IconButton>
                        <IconButton className='navbar-button-lupa' onClick={() => setDisplayLanguage(true)}>
                            <img alt='Language' src={Lang} className='navbar-button-img' />
                        </IconButton>
                    </div>
                </div>
            </nav>

            <div className={displayLanguage ? 'lang-black-div' : 'lang-black-div-hidden'}>
                <div className={displayLanguage ? 'lang-black-innerdiv' : 'lang-black-innerdiv-hidden'}>
                    <img
                        alt='language'
                        src={LangLight} className='navbar-button-img'
                        style={displayLanguage ? { transform: 'scale(1.1)', cursor: 'pointer' } : { display: 'none' }}
                        onClick={() => setDisplayLanguage(false)}
                    />
                </div>
                <div className={displayLanguage ? 'lang-black-menu' : 'lang-black-menu-hidden'}>
                    <MenuItem id='pt' sx={displayLanguage ? { color: 'white', '&:hover': { color: '#5a6a41' } } : { display: 'none' }} onClick={changeLanguage}><img alt='PT' src={PT} style={{ width: '30px', marginRight: '5px' }} /> Portugês</MenuItem>
                    <MenuItem id='en-GB' sx={displayLanguage ? { color: 'white', '&:hover': { color: '#5a6a41' } } : { display: 'none' }} onClick={changeLanguage}><img alt='EN' src={UK} style={{ width: '30px', marginRight: '5px' }} /> English</MenuItem>
                </div>
            </div>

            <div className={displaySearch ? 'search-black-div' : 'search-black-div-hidden'}>
                <div className={displaySearch ? 'search-black-innerdiv' : 'search-black-innerdiv-hidden'}>
                    <img
                        alt='search'
                        src={LupaLight} className='navbar-button-img'
                        style={displaySearch ? { transform: 'scale(1.1)', cursor: 'pointer' } : { display: 'none' }}
                        onClick={() => setDisplaySearch(false)}
                    />
                </div>
                <div className={displaySearch ? 'search-black-button' : 'search-black-button-hidden'}>
                    <ArrowRight
                        sx={displaySearch ? { cursor: 'pointer', color: 'white', transform: 'scale(2)', '&:hover': { transform: 'scale(2.5)' } } : { display: 'none' }}
                        onClick={NavigateToSearch}
                    />
                </div>
            </div>
            <input
                ref={inputRef}
                className={displaySearch ? 'search-input' : 'search-input-hidden'}
                type='text'
                placeholder={t('navbar.buttons.inputplaceholder')}
                value={searchData}
                onChange={searchInput}
                onKeyPress={e => { if (e.key === 'Enter') NavigateToSearch() }}
            />

            <div
                className={displaySearch || displayLanguage ? 'modal-background-div' : ''}
                onClick={() => { setDisplaySearch(false); setDisplayLanguage(false) }}
            >
            </div>
        </div>
    )
}

export default Navbar
