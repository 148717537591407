import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import { Dialog, DialogContent, Card, CardHeader, Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import EmailDialog from '../apoio/EmailDialog';
import iconPlaceholder from '../../assets/icons/logo_placeholder_1.png'

import './ApoioDialog.css'

const isMobile = () => {
    return window.innerWidth < 900;
}

function ApoioDialog(props) {
    const [openEmailDialog, setOpenEmailDialog] = useState(false)
    const { t, i18n } = useTranslation('translation')

    if (!props.data) return '';

    return (
        <div>
            <Dialog
                fullScreen={isMobile() ? true : false}
                maxWidth='md'
                open={props.open}
                onClose={props.close}
                aria-labelledby='max-width-dialog'
                className='apoiodialog-main-div'
            >
                <DialogContent className='apoio-dialog-content'>
                    <CloseIcon className='close-button' onClick={props.close} />
                    <Card elevation={3} className='dialog-top-card'>
                        <CardHeader className='dialog-top-card-header'
                            avatar={
                                <img src={props.data.icon ? `${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${props.data.icon.icon}` : iconPlaceholder} alt='icon' height='45px' width='45px' />
                            }
                            action={
                                <div className='dialog-top-card-header-button' onClick={() => { setOpenEmailDialog(true); props.close() }}>{t('common.supportProject')}</div>
                            }
                            title={<p style={{ color: 'black' }}>{i18n.language === 'pt' ? props.data.title_pt : props.data.title_en}</p>}
                            subheader={<p style={{ color: '#b3a698' }}>{props.project.title}</p>}
                        />
                    </Card>
                    <p style={{ fontSize: '24px', fontWeight: 700, textTransform: 'uppercase', marginBottom: '10px' }}>{t('projects.help.dialog.title')}</p>
                    <div className='helper-inner-div'>
                        {props.data.contributions.length > 0 ?
                            props.data.contributions.map(helper => {
                                return (
                                    <Card elevation={3} key={helper.id} className='helper-card'>
                                        <div className='helper-card-logo'>
                                            <img src={`${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${helper.partner.logo}`} alt='logo' />
                                        </div>
                                        <Divider style={{ height: '90%', width: '1px', backgroundColor: 'black' }} />
                                        <div className='helper-card-info'>
                                            <p style={{ fontWeight: 700 }}>{helper.partner.name}</p>
                                            <p style={{ color: '#888' }}>{helper.partner.address}</p>
                                            <p style={{ color: '#888' }}>{helper.partner.contact}</p>
                                            <p style={{ color: '#888' }}>{helper.partner.website}</p>
                                        </div>
                                    </Card>
                                )
                            }) :
                            <div className='help-dialog-no-contributions-div'>
                                <p className='help-dialog-no-contributions-text'>{t('projects.help.dialog.nooneHelped')}</p>
                                <div className='dialog-top-card-header-button' onClick={() => { setOpenEmailDialog(true); props.close() }}>{t('common.supportProject')}</div>
                            </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
            <EmailDialog
                open={openEmailDialog}
                close={() => setOpenEmailDialog(false)}
                allTasks={props.allTasks}
                selectedTask={props.data}
                project={props.project}
            />
        </div>
    )
}


export default ApoioDialog
