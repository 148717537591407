import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import StandardButton from '../Buttons/Standard'
import ShareDialog from '../shareDialog/ShareDialog'
import NewsSmallCard from '../cards/NewsSmallCard'
import { useTranslation } from 'react-i18next'

import { getSuggestedNews } from '../../data/Themes'
import { fetchNews } from '../../data/News'
import regexifyString from 'regexify-string'

import IconShare from '../../assets/icons/iconShare.svg'

import { trimText, openInNewTab, addShare, addView } from '../../utils'
import ClickAwayListener from '@mui/material/ClickAwayListener';

import './NewsInfo.css'

function NewsInfo() {
	const [openShare, setOpenShare] = useState(false)
	const [suggestedNews, setSuggestedNews] = useState([])
	const [data, setData] = useState()

	const { t, i18n } = useTranslation('translation')
	const { state } = useLocation()
	const navigate = useNavigate()
	const urlParams = new URLSearchParams(window.location.search)
	const containerRef = useRef(null)

	useEffect(() => {
		if (state) {
			setData(state.news)
			getSuggestedNews(state.news.newsThemeId, state.news.id, setSuggestedNews)
			addView('news', state.news.id)

		} else {
			if (!urlParams.get('id') || !urlParams.get('tid')) {
				navigate('/noticias');
				return;
			}
			fetchNews(urlParams.get('id'), setData)
			getSuggestedNews(urlParams.get('tid'), urlParams.get('id'), setSuggestedNews)
			addView('news', urlParams.get('id'))

		}

		if (containerRef.current) {
			containerRef.current.parentNode.scrollTop = 0
		}
	}, [state])

	const regexifyPattern = /\[\[(.*?)\]\]/gim;
	const regexifyDecorator = (match, index) => {
		const string = match.substring(2, match.length - 2)
		if (string.includes('@')) {
			return <a key={match} className='text-link' href={`mailto:${string.split('|')[0]}`}>{string.split('|')[1]}</a>
		}
		return <label key={match} className='text-link' onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</label>
	}

	return (
		<>
			{data ?
				<div className='news-info-main-div' ref={containerRef}>
					<div className='news-info-info' style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/news/${data.image})` }}>
						<div className='news-info-background' />
						<div className='news-info-left-div'>
							<p className='news-info-left-div-title'>{i18n.language === 'pt' ? data.title_pt : data.title_en}</p>
							<p className='news-info-left-div-location'>{data.location}</p>
							<p className='news-info-left-div-description'>
								{i18n.language === 'pt' ?
									regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: data.description_pt })
									:
									regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: data.description_en })
								}
							</p>
							<div className='news-info-left-div-bottom'>
								<StandardButton onClick={() => navigate(-1)} >{t('news.common.back')}</StandardButton>
								<ClickAwayListener onClickAway={() => setOpenShare(false)}>
									<div style={{ height: '60px', display: 'flex', alignItems: 'center' }}>
										<ShareDialog
											placement='top'
											open={openShare}
											onClose={() => setOpenShare(false)}
											url={`${process.env.REACT_APP_THIS_URL}/noticias/${trimText(data.title_pt)}?id=${data.id}&tid=${data.newsThemeId}`}
											quote={i18n.language === 'pt' ? data.title_pt : data.title_en}
											hashtag='#lugar'
											description={i18n.language === 'pt' ? data.description_pt : data.description_en}
											countshare={() => addShare('news', data.id)}
										>
											<div className='share-social-button'
												onClick={() => setOpenShare(!openShare)}
											>
												<img alt='share social' src={IconShare} />
											</div>
										</ShareDialog>
									</div>
								</ClickAwayListener>
							</div>
						</div>
					</div>
					<div className='news-info-recommended'>
						<p className='news-info-recommended-title'>{t('news.common.suggested')}</p>
						{suggestedNews?.map((news, index) => {
							return (
								<NewsSmallCard key={news.id} news={news} index={index} />
							)
						})}
					</div>
				</div>
				:
				<div className='no-results-div'>
					<p className='no-results-text'>{t('news.common.noresults')}</p>
					<StandardButton
						style={{ color: 'var(--dark-beige)', borderColor: 'var(--dark-beige)' }}
						onClick={() => navigate('/noticias')}
					>
						{t('news.common.back')}
					</StandardButton>
				</div>
			}</>
	)
}

export default NewsInfo