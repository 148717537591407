import React from 'react'
import bigX from '../../assets/icons/bigX.svg'
import bigCheck from '../../assets/icons/bigCheck.svg'
import StandardButton from '../Buttons/Standard'
import { useTranslation } from 'react-i18next'

import { LoadingGrid } from '../Loading/LoadingGrid'

import { Dialog, DialogContent } from '@mui/material'

import './Confirmation.css'

function Confirmation({ open, close, fase, data }) {

    const { t } = useTranslation('translation')

    const getIcon = () => {
        switch (fase) {
            case 1: return <LoadingGrid label='' />
            case 2: return <img alt='icon' src={bigCheck} height='150px' width='150px' />
            case 3: return <img alt='icon' src={bigX} height='150px' width='150px' />
            default: return ''
        }
    }

    const getText = () => {
        switch (fase) {
            case 1: return <p>{t('contacts.dialogLoading')}</p>
            case 2: return <p>{t('contacts.dialogSuccess')}</p>
            case 3: return <p>{t('contacts.dialogError')}</p>
            default: return ''
        }
    }

    const handleClose = () => {
        if (fase === 1) return;
        close()
    }

    return (
        <Dialog
            maxWidth='md'
            fullScreen={window.innerWidth < 960}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
            className='confirmation-Dialog-main'
        >
            <DialogContent className='confirmation-dialog-content'>
                <div className='confirmation-dialog-middle'>
                    <div className='confirmation-dialog-icon'>
                        {getIcon()}
                    </div>
                    <div className='confirmation-dialog-text'>
                        {getText()}
                    </div>
                </div>
                {fase !== 1 &&
                    <div className='confirmation-dialog-action'>
                        <StandardButton style={{ color: 'var(--dark-beige)', borderColor: 'var(--dark-beige)' }} onClick={handleClose}>OK</StandardButton>
                    </div>
                }
            </DialogContent>
        </Dialog>
    )
}

export default Confirmation