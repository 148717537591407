import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Newsletter from '../Dialog/Newsletter';
import { Tooltip } from '@mui/material'

import AMLogo from '../../assets/footer/AM.png'
import FBLogo from '../../assets/footer/FB.png'
import IGLogo from '../../assets/footer/INSTA.png'
import LILogo from '../../assets/footer/LINKEDIN.png'
import BlogLogo from '../../assets/footer/blog.jpg'
import barLogo from '../../assets/footer/cofinanciamento.png'

import './Footer.css'

function Footer() {
    const [openNewsletter, setOpenNewsletter] = useState(false)
    const [t] = useTranslation('translation')

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
        if (newWindow) newWindow.opener = null
    }

    return (
        <div className='footer-container'>
            <div className='footer-left-side'>
                <p onClick={() => setOpenNewsletter(true)}>{t('footer.subscribe')}<span> NEWSLETTER</span></p>
            </div>
            <img alt='cofinanciamento'src={barLogo} className='cofinanciamento-logo' />
            <div className='footer-right-side'>
                <div className='footer-logo'>
                    <img alt='AM-logo' src={AMLogo} className='footer-am-logo' onClick={() => openInNewTab('https://www.aldeiasdemontanha.pt/')} />
                </div>
                <div className='footer-social'>
                    <Tooltip title='Facebook'>
                        <img alt='Facebook logo' src={FBLogo} onClick={() => openInNewTab('https://www.facebook.com/aldeiasmontanha')} />
                    </Tooltip>
                    <Tooltip title='Instagram'>
                        <img alt='Instagram logo' src={IGLogo} onClick={() => openInNewTab('https://www.instagram.com/aldeiasdemontanha/')} />
                    </Tooltip>
                    <Tooltip title='LinkedIn'>
                        <img alt='Linkedin logo' src={LILogo} onClick={() => openInNewTab('https://www.linkedin.com/company/aldeias-de-montanha/')} />
                    </Tooltip>
                    <Tooltip title='Blog'>
                        <img alt='Blog logo' src={BlogLogo} onClick={() => openInNewTab('https://blog.aldeiasdemontanha.pt/')} />
                    </Tooltip>
                </div>
            </div>
            <img alt='cofinanciamento'src={barLogo} className='cofinanciamento-logo-mobile' />
            <Newsletter open={openNewsletter} close={() => setOpenNewsletter(false)} />
        </div>
    )
}

export default Footer
