import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { openInNewTab, trimText, limitTextSize } from '../../utils'
import regexifyString from 'regexify-string'

import './SearchCards.css'

function SearchCards({ type, ...props }) {
    const navigate = useNavigate()

    const regexifyPattern = /\[\[(.*?)\]\]/gim;
    const regexifyDecorator = (match, index) => {
        const string = match.substring(2, match.length - 2)
        if (string.includes('@')) {
            return <a key={match} className='text-link' style={{color: 'black'}} href={`mailto:${string.split('|')[0]}`}>{string.split('|')[1]}</a>
        }
        return <label key={match} className='text-link' style={{color: 'black'}} onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</label>
    }

    return (
        type === 'project' ?
            <ProjectCard data={props.data} decorator={regexifyDecorator} pattern={regexifyPattern} onClick={() => navigate(`/projetos/${trimText(props.data.title)}?pid=${props.data.id}`, { state: { data: props.data } })} />
            :
            <NewsCard data={props.data} decorator={regexifyDecorator} pattern={regexifyPattern} onClick={() => navigate(`/noticias/${trimText(props.data.title_pt)}?id=${props.data.id}&tid=${props.data.newsThemeId}`, { state: { news: props.data } })} />
    )
}

const ProjectCard = (props) => {
    const { i18n, t } = useTranslation('translation')

    return (
        <div className='search-card-main-div' onClick={props.onClick}>
            <img className='search-card-img' alt='project' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${props.data.background_picture}`} />
            <div className='search-card-info-div'>
                <p className='search-card-type'>{t('search.project')}</p>
                <p className='search-card-title'>{props.data.title}</p>
                <p className='search-card-subtitle'>
                    {i18n.language === 'pt' ?
                        regexifyString({ pattern: props.pattern, decorator: props.decorator, input: limitTextSize(props.data.project_description_pt, 350, true) })
                        :
                        regexifyString({ pattern: props.pattern, decorator: props.decorator, input: limitTextSize(props.data.project_description_en, 350, true) })
                    }
                </p>
            </div>
        </div>
    )
}

const NewsCard = (props) => {
    const { i18n, t } = useTranslation('translation')
    moment.locale(i18n.language)

    return (
        <div className='search-card-main-div' onClick={props.onClick}>
            <img className='search-card-img' alt='news' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/news/${props.data.image}`} />
            <div className='search-card-info-div'>
                <div className='search-type-date-div'>
                    <p className='search-card-type'>{t('search.news')}</p>
                    <p className='search-card-date'>{moment(props.data.createdAt).format('dddd, DD MMMM YYYY')}</p>
                </div>
                <p className='search-card-title'>{i18n.language === 'pt' ? props.data.title_pt : props.data.title_en}</p>
                <p className='search-card-subtitle'>
                    {i18n.language === 'pt' ?
                        regexifyString({ pattern: props.pattern, decorator: props.decorator, input: limitTextSize(props.data.description_pt, 350, true) })
                        :
                        regexifyString({ pattern: props.pattern, decorator: props.decorator, input: limitTextSize(props.data.description_en, 350, true) })
                    }
                </p>
            </div>
        </div>
    )
}

export default SearchCards