import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import AwesomeSlider from 'react-awesome-slider'
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import arraySort from 'sort-array'

import { useOutletContext } from 'react-router-dom';
import { Link } from 'react-router-dom'

import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import moment from 'moment'
import 'moment/locale/pt'
import 'moment/locale/en-gb'

import '../pages/css/HomeSlider.css';
import './NewsSlider.css'
import { trimText } from '../../utils';


const AutoplaySlider = withAutoplay(AwesomeSlider);

function NewsSlider() {
    const [data, setData] = useState([])

    const [themes] = useOutletContext()
    const { t, i18n } = useTranslation('translation')

    moment.locale(i18n.language)

    useEffect(() => {
        setData(createNewsToSlider(themes))
    }, [themes])

    return (
        data.length > 0 ?
            <AutoplaySlider
                fillParent={true}
                bullets={false}
                organicArrows={false}
                buttonContentRight={<ArrowRight className='arrow-right' />}
                buttonContentLeft={<ArrowLeft className='arrow-left' />}
                play={true}
                interval={5000}
                cancelOnInteraction={true}
                style={{ animation: 'newsSlider 0.7s ease-in-out' }}
            >
                {data.map((elem, index) => {
                    return (
                        <div key={elem.id} className='news-card'
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/news/${elem.image})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}
                        >
                            <div className='news-card-left-side'>
                                <p className='news-card-date'>{moment(elem.createdAt).format('dddd, DD MMMM YYYY')}</p>
                                <p className='news-card-title'>{i18n.language === 'pt' ? elem.title_pt : elem.title_en}</p>
                                <p className='news-card-subtitle'>{elem.location}</p>
                                <Link className='news-card-button' to={`/noticias/${trimText(elem.title_pt)}?id=${elem.id}&tid=${elem.newsThemeId}`} state={{ news: elem }}>
                                    {t('common.seemore')}
                                </Link>
                                <p className='news-card-counter'>{`${index + 1}/${data.length}`}</p>
                            </div>
                        </div>
                    )
                })}
            </AutoplaySlider>
            :
            <p className='no-results-text'>{t('news.common.nonews')}</p>
    )
}

export default NewsSlider

const createNewsToSlider = (data) => {
    const result = []

    data.forEach((elem, index) => {
        if (elem.news.length > 0) {
            const news = elem.news.filter(news => news.published)
            const sortedNews = arraySort(news, { by: 'publish_date', order: 'desc' })
            if (sortedNews.length > 0) result.push(sortedNews[0])
        }
    })

    return result
}