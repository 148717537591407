import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import NewsBigCard from '../cards/NewsBigCard'
import StandardButton from '../Buttons/Standard'
import { useTranslation } from 'react-i18next'

import { getEpisodesFromTheme } from '../../data/Themes'

function NewsTheme() {
    const { state } = useLocation()
    const urlParams = new URLSearchParams(window.location.search)
    const containerRef = useRef(null)
    const { t } = useTranslation('translation')
    const navigate = useNavigate()

    const [data, setData] = useState([])

    useEffect(() => {
        if (state) {
            setData(state.theme.news)
        } else {
            if (!urlParams.get('id')) {
                navigate('/noticias');
                return;
            }
            getEpisodesFromTheme(urlParams.get('id'), setData)
        }
    }, [state])

    return (
        <div className='news-list-main-div' ref={containerRef}>
            {data?.length > 0 ?
                <>
                    <div className='news-list-content-div' >
                        {data.map((elem, index) => {
                            return (
                                <NewsBigCard news={elem} index={index} key={index} />
                            )
                        })}
                    </div>
                    <div className='news-list-view-more-btn-div'>
                        {containerRef.current.scrollTop > 300 &&
                            <StandardButton
                                styleType='round'
                                style={{ borderColor: 'var(--dark-beige)', color: 'var(--dark-beige)' }}
                                onClick={() => containerRef.current.scrollTop = 0}
                            >
                                {t('news.common.toTop')}
                            </StandardButton>
                        }
                    </div>
                </>
                :
                <div className='no-results-div'>
                    <p className='no-results-text'>{t('news.common.nonews')}</p>
                    <StandardButton
                        style={{ color: 'var(--dark-beige)', borderColor: 'var(--dark-beige)' }}
                        onClick={() => navigate(-1)}
                    >
                        {t('news.common.back')}
                    </StandardButton>
                </div>
            }
        </div>
    )
}

export default NewsTheme