export default function useWheel() {

    const onWheel = (apiObj, ev, scrollContainer) => {
        const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

        if (isThouchpad) {
            ev.stopPropagation();
            return;
        }

        if (ev.deltaY < 0) {
            sideScroll(scrollContainer, 'left', 15, 200, 10)
        } else {
            sideScroll(scrollContainer, 'right', 15, 200, 10)
        }
    }

    const sideScroll = (element, direction, speed, distance, step) => {
        if(!element) return;
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                window.clearInterval(slideTimer)
            }
        }, speed)
    }

    return {
        onWheel,
        sideScroll
    }
}