import React, { Component } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { Dialog, Paper, Autocomplete, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import Confirmation from '../Dialog/Confirmation'

import './EmailDialog.css'
import StandardButton from '../Buttons/Standard'
import { Link } from 'react-router-dom'
import iconPlaceholder from '../../assets/icons/logo_placeholder_1.png'

const isMobile = () => {
    return window.innerWidth < 900;
}

const CustomPaper = (props) => {
    return <Paper {...props} sx={{ borderRadius: '20px' }} />
}

class EmailDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            phone: '',
            email: '',
            message: '',
            tasks: this.props.allTasks,
            selectedTask: this.props.selectedTask,
            project: this.props.project,
            formErrors: { name: '', phone: '', email: '', message: '' },
            nameIsValid: false,
            phoneIsValid: false,
            emailIsValid: false,
            messageIsValid: false,
            formIsValid: false,
            submiting: false,
            openDialog: false,
            dialogFase: 1,
        }
    }

    setInitialState = () => {
        this.setState({
            name: '',
            phone: '',
            email: '',
            message: '',
            tasks: this.props.allTasks,
            selectedTask: this.props.selectedTask,
            project: this.props.project,
            formErrors: { name: '', phone: '', email: '', message: '' },
            nameIsValid: false,
            phoneIsValid: false,
            emailIsValid: false,
            messageIsValid: false,
            formIsValid: false,
            submiting: false,
            openDialog: false,
            dialogFase: 1,
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedTask !== this.props.selectedTask) {
            this.setState({ selectedTask: this.props.selectedTask })
        }
    }

    handleInput(event, t) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value }, () => this.validateField(name, value, t))
    }

    handleAutocomplete = (value) => {
        this.setState({ selectedTask: value })
    }

    validateField(fieldName, value, t) {
        let fieldValidationErrors = this.state.formErrors;
        let nameIsValid = this.state.nameIsValid;
        let phoneIsValid = this.state.phoneIsValid;
        let emailIsValid = this.state.emailIsValid;
        let messageIsValid = this.state.messageIsValid;

        switch (fieldName) {
            case 'name':
                nameIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/).test(value) && value.length > 4;
                fieldValidationErrors.name = nameIsValid ? '' : value.length > 0 ? `${value} ${t('formValidation.name')}` : `${t('formValidation.required')}`;
                break;
            case 'email':
                emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
                fieldValidationErrors.email = emailIsValid ? '' : value.length > 0 ? `${value} ${t('formValidation.email')}` : `${t('formValidation.required')}`;
                break;
            case 'phone':
                phoneIsValid = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/).test(value) && (value.length >= 9);
                fieldValidationErrors.phone = phoneIsValid ? '' : value.length > 0 ? `${value} ${t('formValidation.phone')}` : `${t('formValidation.required')}`;
                break;
            case 'message':
                messageIsValid = value.length > 10;
                fieldValidationErrors.message = messageIsValid ? '' : value.length > 0 ? `${t('formValidation.message')}` : `${t('formValidation.required')}`;
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            nameIsValid: nameIsValid,
            phoneIsValid: phoneIsValid,
            emailIsValid: emailIsValid,
            messageIsValid: messageIsValid
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.nameIsValid &&
                this.state.emailIsValid &&
                this.state.phoneIsValid &&
                this.state.messageIsValid
        })
    }

    errorClass(error) {
        return (!error ? '' : 'error')
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ openDialog: true })

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/sendapoioemail`, {
            userName: this.state.name,
            userPhone: this.state.phone,
            userEmail: this.state.email,
            message: this.state.message,
            item: this.state.selectedTask,
            project: this.state.project,
            language: this.props.i18n.language
        }, { headers: { key: process.env.REACT_APP_API_KEY } }).then((res) => {
            this.setState({ dialogFase: 2 })
        }, err => {
            this.setState({ dialogFase: 3 })
            console.log(err)
        })
    }

    render() {
        const { t, i18n } = this.props;

        return (
            <Dialog
                fullScreen={isMobile() ? true : false}
                fullWidth={true}
                maxWidth='xl'
                PaperComponent={CustomPaper}
                open={this.props.open}
                onClose={this.props.close}
                aria-labelledby='max-width-dialog'
                className='emaildialog-main-div'
            >
                <CloseIcon htmlColor='white' className='close-button-email' onClick={this.props.close} />
                <div className='emaildialog-content-div'>
                    <div className='emaildialog-form-div'>
                        <form className='emaildialog-form' onSubmit={this.handleSubmit}>
                            <div className='name-phone-fields'>
                                <label className='emaildialog-labels'>
                                    <div className='labels-title'>{t('common.name')}</div>
                                    <input
                                        className={`emaildialog-input ${this.errorClass(this.state.formErrors.name)}`}
                                        name='name'
                                        placeholder='-'
                                        value={this.state.name}
                                        onChange={event => this.handleInput(event, t)}
                                    />
                                    <p style={{ marginLeft: '10px', color: '#bd0000', textShadow: '0px 0px 10px white' }}>{this.state.formErrors['name']}</p>
                                </label>
                                <label className='emaildialog-labels'>
                                    <div className='labels-title'>{t('common.phone')}</div>
                                    <input
                                        className={`emaildialog-input ${this.errorClass(this.state.formErrors.phone)}`}
                                        name='phone'
                                        placeholder='-'
                                        value={this.state.phone}
                                        onChange={event => this.handleInput(event, t)}
                                    />
                                    <p style={{ marginLeft: '10px', color: '#bd0000', textShadow: '0px 0px 10px white' }}>{this.state.formErrors['phone']}</p>
                                </label>
                            </div>
                            <div className='email-item-fields'>
                                <label className='emaildialog-email-label' htmlFor='email'>
                                    <div className='labels-title'>EMAIL</div>
                                    <input
                                        className={`emaildialog-input ${this.errorClass(this.state.formErrors.email)}`}
                                        name='email'
                                        placeholder='-'
                                        value={this.state.email}
                                        onChange={event => this.handleInput(event, t)}
                                    />
                                    <p style={{ marginLeft: '10px', color: '#bd0000', textShadow: '0px 0px 10px white' }}>{this.state.formErrors['email']}</p>
                                </label>
                            </div>
                            <div className='item-field'>
                                <label className='emaildialog-labels-select'>
                                    <div className='labels-title'>Item</div>
                                    <Autocomplete
                                        id="items"
                                        options={this.state.tasks}
                                        openOnFocus
                                        disableClearable
                                        noOptionsText={<p>Sem opção</p>}
                                        value={this.state.selectedTask}
                                        onChange={(e, newValue) => this.handleAutocomplete(newValue)}
                                        getOptionLabel={(option) => i18n.language === 'pt' ? option.title_pt : option.title_en}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderOption={(props, option) => (
                                            <div {...props} style={{ height: 'fit-content', display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                                <img alt='item icon' src={option.icon ? `${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${option.icon.icon}` : iconPlaceholder} width='20px' height='20px' />
                                                <p style={{ marginLeft: '10px' }}>{i18n.language === 'pt' ? option.title_pt : option.title_en}</p>
                                            </div>
                                        )}
                                        renderInput={(params) => (
                                            <TextField className='emaildialog-task-autocomplete' sx={{ backgroundColor: 'white', borderRadius: '5px' }} fullWidth {...params} variant='standard' />
                                        )}
                                    />
                                </label>
                                <TaskPreview i18n={i18n} task={this.state.selectedTask} />
                            </div>
                            <div className='message-field'>
                                <label className="message-label">
                                    <div className="labels-title">{t('common.helpTitle')}</div>
                                    <textarea rows={10} className={`email-text-area ${this.errorClass(this.state.formErrors.message)}`} name="message" placeholder="-"
                                        value={this.state.message}
                                        onChange={(event) => this.handleInput(event, t)} />
                                    <p style={{ marginLeft: '10px', color: '#bd0000', textShadow: '0px 0px 10px white' }}>{this.state.formErrors["message"]}</p>
                                </label>
                            </div>
                            <div className='emaildialog-button-div'>
                                <StandardButton style={{ margin: ' 0 5px' }} styleType='standard' type='submit' disabled={!this.state.formIsValid}>{t('common.submit')}</StandardButton>
                            </div>
                        </form>
                    </div>
                    <div className='emaildialog-text-div'>
                        <p className='text-div-upper-1'>{t('projects.help.emailDialog.text1')}</p>
                        <p className='text-div-upper-2'>{t('projects.help.emailDialog.text2')}</p>
                        <p className='text-div-bottom'>
                            <Trans i18nKey={'projects.help.emailDialog.text3'}>
                                {t('projects.help.emailDialog.text3')}<Link className='inline-link' style={{ color: 'white' }} to='/contactos'>link</Link>
                            </Trans>
                        </p>
                    </div>
                </div>
                <Confirmation open={this.state.openDialog} close={() => { this.setInitialState(); this.props.close() }} fase={this.state.dialogFase} />
            </Dialog>
        )
    }
}

export default withTranslation('translation')(EmailDialog)


const TaskPreview = ({ task, i18n }) => {
    return (
        <div className='emaildialog-item-preview'>
            <img src={ task.icon ? `${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${task.icon.icon}` : iconPlaceholder} alt='icon' />
            <div>
                <p>{i18n.language === 'pt' ? task.title_pt : task.title_en}</p>
                <p className='task-progress'>{calculateContributionAmount(task.contributions)} / {task.milestone}</p>
            </div>
        </div>
    )
}

const calculateContributionAmount = (contributions) => {
    if (contributions.length === 0) return 0
    const amount = contributions.reduce((prev, current) => {
        return {
            quantity: prev.quantity + current.quantity
        }
    })

    return amount.quantity
}