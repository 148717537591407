import React, { useState, useEffect, useRef } from 'react'
import { SVGMap } from 'react-svg-map'
import map from '../../assets/map/svgmap/index'
import { fetchProjects } from '../../data/Projects'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent } from '@mui/material'
import { Close } from '@mui/icons-material'

import compassImg from '../../assets/map/BUSSOLA_1-50.svg'

import './css/Map.css'

const tooltipPos = { top: 0, right: 0 }

function Map() {
    const [projects, setProjects] = useState([])
    const [hoveredProjects, setHoveredProjects] = useState([])
    const [activePins, setActivePins] = useState([])
    const [showTooltip, setShowTooltip] = useState(false)
    const [showMobileTooltip, setShowMobileTooltip] = useState(false)
    const [isMouseHoverTooltip, setIsMouseHoverTooltip] = useState(false)
    const { t } = useTranslation('translation')
    const tooltipRef = useRef(null)

    const navigate = useNavigate()

    let selectedRegionElem;

    useEffect(() => {
        fetchProjects(setProjects)
    }, [])

    useEffect(() => {
        projects.forEach(proj => {
            setActivePins(prev => [...prev, getLocationPin(proj.location.id)])
        })
    }, [projects])

    const checkMousePos = (e) => {
        if (!tooltipRef.current) return;
        if (e.pageX >= tooltipRef.current.offsetLeft && e.pageX <= tooltipRef.current.offsetLeft + tooltipRef.current.clientWidth) {
            if (e.pageY >= tooltipRef.current.offsetTop && e.pageY <= tooltipRef.current.offsetTop + tooltipRef.current.clientHeight) {
                setIsMouseHoverTooltip(true);
                return;
            }
        }
        setIsMouseHoverTooltip(false)
    }

    const getLocationPin = (locationID) => {
        switch (locationID) {
            case 1: return "190";
            case 2: return "187";
            case 3: return "191";
            case 4: return "185";
            case 5: return "189";
            case 6: return "186";
            case 7: return "192";
            case 8: return "193";
            case 9: return "188";
            default: return '0'
        }
    }

    const handleMapMouseOver = (location) => {
        const regionProjects = checkIfRegionHasProjects(location.target.id)
        setShowTooltip(false)

        if (regionProjects.length > 0) {
            setHoveredProjects(regionProjects)
            setShowTooltip(true)
            selectedRegionElem = document.querySelector(`.svg-map__location--id${location.target.id}`)
            const rect = selectedRegionElem.getBoundingClientRect()
            tooltipPos.top = rect.top
            tooltipPos.left = rect.left
        }
    }

    const handleMobileMapClick = (location) => {
        const regionProjects = checkIfRegionHasProjects(location.target.id)
        setShowMobileTooltip(false)

        if (regionProjects.length > 0) {
            setHoveredProjects(regionProjects)
            setShowMobileTooltip(true)
        }
    }

    const checkIfRegionHasProjects = (locationID) => {
        const projectsOnLocation = []

        projects.forEach(project => {
            if (project.location.id === parseInt(locationID)) {
                projectsOnLocation.push(project)
            }
        })

        return projectsOnLocation
    }

    const getLocationClassName = (location, index) => {
        if (activePins.includes(location.id)) {
            return `svg-map__location--pin${location.id}`
        }

        if (location.id > 184) {
            return `svg-map__location--pin--hidden`
        }

        if (location.id > 9 && location.id < 185) {
            return `svg-map__location--names${location.id}`
        }

        if (location.id <= 9) {
            return `svg-map__location--id${location.id}`
        }

        return `svg-map__location--null`
    }

    const handleMapMouseOut = (e) => {
        // verify if the mouse if hovering the tooltip and if this tooltip has class as visible
        if (tooltipRef.current.classList.length > 1 && isMouseHoverTooltip) return;

        setShowTooltip(false)
    }

    return (
        <div className='map-main-div' onMouseMove={checkMousePos}>
            <div className='map-left-div'>
                <img alt='Compass' src={compassImg} width='200px' height='auto' />
                <p className='map-text'>{t('map.text')}</p>
            </div>
            <div className='map-right-div' >
                <SVGMap
                    map={map}
                    onLocationMouseOver={handleMapMouseOver}
                    onLocationMouseOut={handleMapMouseOut}
                    locationClassName={getLocationClassName}
                />
            </div>
            <div className='map-right-div-mobile'>
                <SVGMap
                    map={map}
                    onLocationClick={handleMobileMapClick}
                    locationClassName={getLocationClassName}
                />
            </div>
            <div
                ref={tooltipRef}
                style={{ top: tooltipPos.top, left: tooltipPos.left }}
                className={`project-hover-main-div ${showTooltip ? 'visible' : ''}`}
                onMouseLeave={() => setHoveredProjects([])}
            >
                {hoveredProjects.map(project => {
                    return (
                        <div onClick={() => navigate(`/projetos/${project.title}`, { state: { data: project } })} key={project.id} className='project-card-div'>
                            <div className='project-card-image'>
                                <img alt='project' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${project.background_picture}`} height='100%' width='auto' />
                            </div>
                            <div className='project-card-info'>
                                <p className='project-card-title'>{project.title}</p>
                                <p className='project-card-location'>{project.location.name}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <Dialog
                open={showMobileTooltip}
                onClose={() => setShowMobileTooltip(false)}
                PaperProps={{ style: { margin: '0', width: '90%' } }}
                fullWidth
            >
                <DialogContent sx={{ padding: '0' }}>
                    <div className='mobile-map-top-bar'><Close htmlColor='white' onClick={() => setShowMobileTooltip(false)} /></div>
                    {hoveredProjects.map(project => {
                        return (
                            <div onClick={() => navigate(`/projetos/${project.title}`, { state: { data: project } })} key={project.id} className='project-card-div'>
                                <div className='project-card-image'>
                                    <img alt='project' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${project.background_picture}`} height='100%' width='auto' />
                                </div>
                                <div className='project-card-info'>
                                    <p className='project-card-title'>{project.title}</p>
                                    <p className='project-card-location'>{project.location.name}</p>
                                </div>
                            </div>
                        )
                    })}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Map