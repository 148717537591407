import React from 'react'
import PropTypes from 'prop-types'

import './Standard.css'


function StandardButton({ children, styleType, ...props }) {
    return (
        <button className={styleType === 'round' ? 'round-button' : 'standard-button'} {...props}>{children}</button>
    )
}

export default StandardButton

StandardButton.protoTypes = {
    styleType: PropTypes.oneOf(['round', 'standard']),
    children: PropTypes.element.isRequired,
}