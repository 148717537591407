import { Tooltip } from "@mui/material";

export const StyledTooltip = (props) => {

    return (
        <Tooltip
            disableInteractive
            componentsProps={{
                tooltip: {
                    sx: {
                        fontSize: '14px ',
                        backgroundColor: 'rgba(0,0,0,0.7)',
                        textAlign: 'center'
                    }
                }
            }}
            {...props}>
            {props.children}
        </Tooltip>
    )
}