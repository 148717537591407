import React, { useState } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Close } from '@mui/icons-material'
import axios from 'axios'

import './Newsletter.css'

function Newsletter(props) {
    const { t } = useTranslation()
    const [subscribed, setSubscribed] = useState(false)
    const [errMessage, setErrMessage] = useState('')


    const handleSubmit = (e) => {
        e.preventDefault()
        const email = e.target[0].value.trim()

        if (!new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)) {
            setErrMessage('Email inválido')
            return;
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/newsletter/subscribe`, { email }, { headers: { key: process.env.REACT_APP_API_KEY } }).then(res => {
            setSubscribed(true)
        }, err => {
            console.log(err)
            setErrMessage(err.response.data)
        })
    }

    return (
        <Dialog
            maxWidth='xs'
            fullScreen={window.innerWidth < 960}
            open={props.open}
            onClose={props.close}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogContent className='newsletter-dialog-content'>
                <Close className='newsletter-close' onClick={props.close} />
                {subscribed ?
                    <p style={{ textAlign: 'center' }} className='newsletter-dialog-title'>{t('newsletter.response')}</p>
                    :
                    <>
                        <p className='newsletter-dialog-title'>{t('newsletter.title')}</p>
                        <p className='newsletter-dialog-subtitle'>{t('newsletter.subtitle')}</p>
                        <form onSubmit={handleSubmit}>
                            <input
                                autoFocus={true}
                                className='newsletter-input'
                                name='email'
                                placeholder='example@email.com'
                                onChange={() => setErrMessage('')}
                            />
                            <button className='newsletter-button' type='submit'>{t('newsletter.button')}</button>
                        </form>
                        {errMessage.length > 0 &&
                            <p className='newsletter-dialog-message'>{errMessage}</p>
                        }
                    </>
                }
            </DialogContent>
        </Dialog>
    )
}

export default Newsletter