import React from 'react'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, TelegramShareButton, WhatsappShareButton, EmailShareButton, RedditShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon, TelegramIcon, WhatsappIcon, EmailIcon, RedditIcon } from "react-share";
import { Tooltip } from '@mui/material'

import './ShareDialog.css'

function ShareDialog(props) {
    return (
        <Tooltip
            title={<ShareButtons countshare={props.countshare} url={props.url} quote={props.quote} hashtag={props.hashtag} description={props.description} />}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            PopperProps={{
                disablePortal: true,
            }}
            componentsProps={{
                tooltip: {
                    sx: {
                        fontSize: '14px ',
                        backgroundColor: 'transparent',
                        textAlign: 'center',
                    }
                }
            }}
            open={props.open}
            onClose={props.onClose}
            placement={props.placement}
        >
            {props.children}
        </Tooltip>
    )
}

const ShareButtons = (props) => {

    return (
        <div className='main-share-dialog-div'>
            <Tooltip disableInteractive title='Facebook' placement='top'>
                <div>
                    <FacebookShareButton
                        url={props.url}
                        quote={props.quote}
                        hashtag={props.hashtag}
                        description={props.description}
                        beforeOnClick={props.countshare}
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                </div>
            </Tooltip>
            <Tooltip disableInteractive title='Twitter' placement='top'>
                <div>
                    <TwitterShareButton
                        title={props.quote}
                        url={props.url}
                        hashtags={[props.hashtag]}
                        beforeOnClick={props.countshare}
                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </div>
            </Tooltip>
            <Tooltip disableInteractive title='LinkedIn' placement='top'>
                <div>
                    <LinkedinShareButton
                        title={props.quote}
                        url={props.url}
                        summary={props.description}
                        beforeOnClick={props.countshare}
                    >
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </div>
            </Tooltip>
            <Tooltip disableInteractive title='Telegram' placement='top'>
                <div>
                    <TelegramShareButton
                        title={props.quote}
                        url={props.url}
                        beforeOnClick={props.countshare}
                    >
                        <TelegramIcon size={32} round />
                    </TelegramShareButton>
                </div>
            </Tooltip>
            <Tooltip disableInteractive title='WhatsApp' placement='top'>
                <div>
                    <WhatsappShareButton
                        url={props.url}
                        separator='/'
                        beforeOnClick={props.countshare}
                    >
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </div>
            </Tooltip>
            <Tooltip disableInteractive title='Reddit' placement='top'>
                <div>
                    <RedditShareButton
                        title={props.quote}
                        url={props.url}
                        beforeOnClick={props.countshare}
                    >
                        <RedditIcon size={32} round />
                    </RedditShareButton>
                </div>
            </Tooltip>
            <Tooltip disableInteractive title='Email' placement='top'>
                <div>
                    <EmailShareButton
                        subject={props.quote}
                        body={props.description}
                        url={props.url}
                        beforeOnClick={props.countshare}
                    >
                        <EmailIcon size={32} round />
                    </EmailShareButton>
                </div>
            </Tooltip>
        </div>
    )
}

export default ShareDialog
