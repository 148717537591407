import React, { useState, useEffect } from 'react'
import './Card.css'
import { useTranslation } from 'react-i18next';
import iconPlaceholder from '../../assets/icons/logo_placeholder_1.png'


function Card(props) {
    const [percentage, setPercentage] = useState(0);
    const { t, i18n } = useTranslation('translation')

    const calculatePercentage = (current, goal) => {
        const percentage = (current / goal) * 100;
        return Math.floor(percentage * 100) / 100;
    }

    const calculateContributionAmount = (contributions) => {
        if (contributions.length === 0) return 0
        const amount = contributions.reduce((prev, current) => {
            return {
                quantity: prev.quantity + current.quantity
            }
        })

        return amount.quantity
    }

    useEffect(() => {
        setPercentage(calculatePercentage(calculateContributionAmount(props.data.contributions), props.data.milestone))
    }, [props.data])

    return (
        <div className='main-apoio-card-div' {...props}>
            <div className='upper-div'>
                <div className='upper-black-div' style={percentage >= 100 ? { opacity: 0.9 } : { opacity: 0 }}>
                    <p>{t('projects.help.right-side.hover-text')}</p>
                </div>
                {props.data.category === 1 ? (
                    <div className='left-side'>
                        <div className='left-side-text'>{t('projects.help.right-side.missing')}</div>
                        <div className='left-side-quantity'>{props.data.milestone - props.data.current}</div>
                    </div>
                ) : ''}
                <div className='right-side'>
                    <div className='right-side-icon'>
                        <img src={props.data.icon ? `${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${props.data.icon.icon}` : iconPlaceholder} alt='icon' />
                    </div>
                    <div className='right-side-text'>
                        <p>{i18n.language === 'pt' ? props.data.title_pt : props.data.title_en}</p>
                        {props.data.local_craft ? <p>{`(${t('projects.help.right-side.local-craft')})`}</p> : ''}
                    </div>
                </div>
            </div>
            <div className='bottom-div'>
                <div
                    className={percentage > 8 ? 'bottom-div-percentage' : 'bottom-div-percentage-small'}
                    style={{ width: `${percentage}%` }}
                >
                    {`${percentage}%`}
                </div>
            </div>
        </div>
    )
}

export default Card
