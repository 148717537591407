import React from 'react'
import { Dialog, DialogContent, Tooltip } from '@mui/material'
import { Close, OpenInNew } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { openInNewTab } from '../../utils'

import './VrWindow.css'

function VrWindow(props) {
    const { t } = useTranslation('translation')

    return (
        <Dialog
            open={props.open}
            onClose={props.close}
            maxWidth='lg'
            fullScreen={window.innerWidth < 960}
            fullWidth
        >
            <DialogContent className='vr-window-content'>
                <div className='vr-window-top-bar'>
                    <p>{props.project_title}</p>
                    <div className='vr-window-top-bar-action'>
                        <Tooltip disableInteractive title={t('common.openinnew')}>
                            <OpenInNew htmlColor='white' onClick={() => openInNewTab(props.url)} />
                        </Tooltip>
                        <Tooltip disableInteractive title={t('common.close')}>
                            <Close htmlColor='white' onClick={props.close} />
                        </Tooltip>
                    </div>
                </div>
                <div className='vr-window-iframe'>
                    <iframe
                        src='https://faunalabvr.com/repo/adiram/alvocovarzeas/'
                        // src={props.url}
                        referrerPolicy="no-referrer"
                        allowFullScreen
                        title="title test"
                        style={{ width: "100%", height: "100%", border: 'none' }}>
                    </iframe>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default VrWindow