import React, { useState, useEffect } from 'react'
import NewsBigCard from '../cards/NewsBigCard'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios'

import { LoadingGrid } from '../Loading/LoadingGrid';
import StandardButton from '../Buttons/Standard'

import './NewsList.css'

function NewsSearch() {
    const { state } = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation('translation')

    const [data, setData] = useState([])
    const [isSearching, setIsSearching] = useState(false)

    useEffect(() => {
        async function getData() {
            setIsSearching(true);
            await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/searchNews/${state.search}`, {
                headers: { 'key': process.env.REACT_APP_API_KEY }
            }).then(res => {
                setData([...res.data])
                setIsSearching(false)
            }, err => {
                console.log(err)
                setIsSearching(false)
            })
        }
        if (!state) {
            navigate('/noticias')
            return;
        }

        getData()

    }, [state])


    return (
        <div className='news-list-main-div' style={isSearching ? { alignItems: 'center' } : { alignItems: 'flex-start' }}>
            {isSearching && <LoadingGrid label={t('news.common.searching')} />}
            {data?.length > 0 ?
                <div className='news-list-content-div' >
                    {data.map((elem, index) => {
                        return (
                            <NewsBigCard news={elem} index={index} key={index} />
                        )
                    })}
                </div>
                :
                <div className='no-results-div'>
                    <p className='no-results-text'>{t('news.common.noresults')}</p>
                    <StandardButton
                        style={{ color: 'var(--dark-beige)', borderColor: 'var(--dark-beige)' }}
                        onClick={() => navigate('/noticias')}
                    >
                        {t('news.common.back')}
                    </StandardButton>
                </div>
            }
        </div>
    )
}

export default NewsSearch