import React from 'react'
import { limitTextSize } from '../../utils'
import { useTranslation } from 'react-i18next';

import './Card.css'

function Card(props) {
    const { i18n } = useTranslation('translations')

    return (
        <div
            key={props.project.id}
            itemID={props.project.id}
            className='projects-card-div'
            style={{
                width: `${1000 - (props.length * 200)}px`,
                animationDelay: `${(0.2 * props.index)}s`,
                backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${props.project.background_picture})`
            }}
            onClick={props.handleClick}
        >
            <div className='card-black-div'>
                <div className='card-info'>
                    <p className='card-info-title'>{props.project.title}</p>
                    <p className='card-info-subtitle'>{props.project.address}</p>
                </div>
                <div className='card-info-text'>
                    {i18n.language === 'pt' ?
                        <p className='card-info-text-text'>{limitTextSize(props.project.project_description_pt, 400, true)}</p>
                        :
                        <p className='card-info-text-text'>{limitTextSize(props.project.project_description_en, 400, true)}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default Card
