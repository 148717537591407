import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom';
import AwesomeSlider from 'react-awesome-slider'
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { useTranslation } from 'react-i18next';
import { StyledTooltip } from '../StyledToolTip/ToolTip';
import ShareDialog from '../shareDialog/ShareDialog';
import { getProjectFromId } from '../../data/Projects';
import { openInNewTab } from '../../utils';

import { ArrowLeft, ArrowRight, CloseFullscreen, OpenInFull } from '@mui/icons-material'
import { Tooltip } from '@mui/material';
import regexifyString from 'regexify-string'

import ClickAwayListener from '@mui/material/ClickAwayListener';

import './css/HomeSlider.css';
import './css/ProjectsDetails.css';

import Icon360 from '../../assets/icons/icon360.svg'
import IconShare from '../../assets/icons/iconShare.svg'
import { trimText, addShare, addView } from '../../utils';
import VrWindow from '../Dialog/VrWindow';
import iconPlaceholder from '../../assets/icons/logo_placeholder_1.png'

const AutoplaySlider = withAutoplay(AwesomeSlider);

const getRandomMilliseconds = (start, end) => {
    return Math.floor(Math.random() * (end - start)) + start;
}

function ProjectDetails() {
    const [openShare, setOpenShare] = useState(false)
    const [categories, setCategories] = useState([])
    const [project, setProject] = useState()
    const [showVr, setShowVr] = useState(false)
    const [topInfoMinimized, setTopInfoMinimized] = useState(false)
    const [bottomInfoMinimized, setBottomInfoMinimized] = useState(false)
    const [soloInfoMinimized, setSoloInfoMinimized] = useState(false)

    const { t, i18n } = useTranslation('translation')
    const navigate = useNavigate()

    const topBarRef = useRef(null)
    const topInfoRef = useRef(null)
    const bottomInfoRef = useRef(null)
    const bottomBackgroundRef = useRef(null)
    const soloInfoRef = useRef(null)

    const urlParams = new URLSearchParams(window.location.search)
    const { state } = useLocation()

    const regexifyPattern = /\[\[(.*?)\]\]/gim;
    const regexifyDecorator = (match, index) => {
        const string = match.substring(2, match.length - 2)
        if (string.includes('@')) {
            return <a key={match} className='text-link' href={`mailto:${string.split('|')[0]}`}>{string.split('|')[1]}</a>
        }
        return <label key={match} className='text-link' onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</label>
    }

    useEffect(() => {
        window.addEventListener('scroll', handleTopBarScroll)
        return () => window.removeEventListener('scroll', handleTopBarScroll)
    }, [])

    useEffect(() => {
        if (state) {
            setProject(state.data)
            addView('projects', state.data.id)
        } else {
            if (urlParams.get('pid')) {
                getProjectFromId(urlParams.get('pid'), setProject)
                addView('projects', urlParams.get('pid'))
                return;
            }
            navigate('/projetos')
        }
    }, [state])

    useEffect(() => {
        if (project) {
            const categories = project.tasks.map(task => task.category)

            let result = categories.filter(function ({ id }) {
                return !this.has(id) && this.add(id);
            }, new Set())

            setCategories(result)
        }
    }, [project])

    const handleTopBarScroll = () => {
        if (window.scrollY > 99) {
            topBarRef.current.classList.toggle('sticky', true)
        } else {
            topBarRef.current.classList.toggle('sticky', false)
        }
    }

    return (
        <>
            {project &&
                <div className='projects-details-main-div'>
                    <div className='projects-details-top-bar' ref={topBarRef}>
                        <div className='projects-details-project-name'>
                            <p className='project-details-card-title'>{project.title}</p>
                            <p style={{ marginBottom: '5px' }} className='project-details-card-subtitle'>{project.address}</p>
                        </div>
                        <div className='projects-details-top-bar-icons'>
                            {categories.map(cat => {
                                if (cat.hide) return '';
                                return (
                                    <StyledTooltip key={cat.id} placement='bottom' title={`${t(`projects.projectsDetails.section_two.tooltips.projectNeeds`)} ${i18n.language === 'pt' ? cat.name_pt : cat.name_en}`}>
                                        <img alt={cat.name_pt} src={cat.icon ? `${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${cat.icon.icon}` : iconPlaceholder} width='auto' height='30px' />
                                    </StyledTooltip>
                                )
                            })}
                        </div>
                        <div className='projects-details-top-bar-actions'>
                            {project.vr_link &&
                                <>
                                    <StyledTooltip placement='top' title={t('projects.projectsDetails.section_two.tooltips.see360')}>
                                        <div className='view-360-button' onClick={() => setShowVr(true)} >{t('common.see')}<img alt='view 360' src={Icon360} width='45px' /></div>
                                    </StyledTooltip>
                                    <VrWindow open={showVr} close={() => setShowVr(false)} url={project.vr_link} project_title={project.title} />
                                </>
                            }
                            <Link className='apoiar-button-bottom' to={`apoiar?pid=${project.id}`} state={{ data: project }} >{t('common.supportProject')}</Link>
                            <ClickAwayListener onClickAway={() => setOpenShare(false)}>
                                <div className='share-icon' style={{ display: 'flex', alignItems: 'center' }}>
                                    <ShareDialog
                                        placement='bottom'
                                        open={openShare}
                                        onClose={() => setOpenShare(false)}
                                        url={`${process.env.REACT_APP_THIS_URL}/projetos/${trimText(project.title)}?pid=${project.id}`}
                                        quote={project.title}
                                        hashtag='#Lugar'
                                        description={i18n.language === 'pt' ? project.project_description_pt : project.project_description_en}
                                        countshare={() => addShare('projects', project.id)}
                                    >
                                        <div className='share-social-button' onClick={() => setOpenShare(!openShare)} >
                                            <img alt='share social' src={IconShare} />
                                        </div>
                                    </ShareDialog>
                                </div>
                            </ClickAwayListener>
                        </div>
                    </div>
                    {project.display_type === 'split' ?
                        <>
                            <div className='projects-details-upper-div'>
                                <div className='main-slider-mobile-div'>
                                    <AutoplaySlider
                                        fillParent={true}
                                        bullets={false}
                                        organicArrows={false}
                                        buttonContentRight={<ArrowRight className='arrow-right' />}
                                        buttonContentLeft={<ArrowLeft className='arrow-left' />}
                                        play={true}
                                        interval={getRandomMilliseconds(5000, 6000)}
                                        cancelOnInteraction={true}
                                    >
                                        {project.before_pictures.split(',').map(image => {
                                            return (
                                                <div key={image} style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${image})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                                            )
                                        })}

                                    </AutoplaySlider>
                                </div>
                                <div className='upper-details-div-test' ref={topInfoRef}>
                                    {topInfoMinimized ?
                                        <Tooltip disableInteractive={true} title='Maximizar texto'>
                                            <OpenInFull htmlColor='white' className='minimize-icon' onClick={() => { setTopInfoMinimized(false); topInfoRef.current.classList.toggle('minimized', false) }} />
                                        </Tooltip>
                                        :
                                        <Tooltip disableInteractive={true} title='Minimizar texto'>
                                            <CloseFullscreen htmlColor='white' className='minimize-icon' onClick={() => { setTopInfoMinimized(true); topInfoRef.current.classList.toggle('minimized', true) }} />
                                        </Tooltip>
                                    }
                                    <div className='upper-details-text-area-test'>
                                        <p className='project-details-card-description-title'>{t('projects.projectsDetails.section_one.description_title')}</p>
                                        <p className='project-details-card-description-upper'>
                                            {i18n.language === 'pt' ?
                                                regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: project.space_description_pt })
                                                :
                                                regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: project.space_description_en })
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='projects-details-bottom-div'>
                                <div className='main-slider-mobile-div'>
                                    <AutoplaySlider
                                        fillParent={true}
                                        bullets={false}
                                        organicArrows={false}
                                        buttonContentRight={<ArrowRight className='arrow-right' />}
                                        buttonContentLeft={<ArrowLeft className='arrow-left' />}
                                        play={true}
                                        interval={getRandomMilliseconds(5000, 6000)}
                                        cancelOnInteraction={true}
                                    >
                                        {project.after_pictures.split(',').map(image => {
                                            return (
                                                <div key={image} style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${image})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                                            )
                                        })}
                                    </AutoplaySlider>
                                </div>
                                <div className='bottom-details-background-test' ref={bottomBackgroundRef} />
                                <div className='bottom-details-div-test' ref={bottomInfoRef}>
                                    {bottomInfoMinimized ?
                                        <Tooltip disableInteractive={true} title='Maximizar texto'>
                                            <OpenInFull htmlColor='white' className='minimize-icon' onClick={() => { setBottomInfoMinimized(false); bottomInfoRef.current.classList.toggle('minimized', false); bottomBackgroundRef.current.classList.toggle('minimized', false) }} />
                                        </Tooltip>
                                        :
                                        <Tooltip disableInteractive={true} title='Minimizar texto'>
                                            <CloseFullscreen htmlColor='white' className='minimize-icon' onClick={() => { setBottomInfoMinimized(true); bottomInfoRef.current.classList.toggle('minimized', true); bottomBackgroundRef.current.classList.toggle('minimized', true) }} />
                                        </Tooltip>
                                    }
                                    <div className='bottom-details-text-area-test'>
                                        <p className='project-details-card-description-title'>{t('projects.projectsDetails.section_two.description_title')}</p>
                                        <p className='project-details-card-description-bottom'>
                                            {i18n.language === 'pt' ?
                                                regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: project.project_description_pt })
                                                :
                                                regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: project.project_description_en })
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className='projects-details-solo-div'>
                            <div className='main-slider-mobile-div'>
                                <AutoplaySlider
                                    fillParent={true}
                                    bullets={false}
                                    organicArrows={false}
                                    buttonContentRight={<ArrowRight className='arrow-right' />}
                                    buttonContentLeft={<ArrowLeft className='arrow-left' />}
                                    play={false}
                                    interval={getRandomMilliseconds(5000, 6000)}
                                    cancelOnInteraction={true}
                                >
                                    {project.after_pictures.split(',').map(image => {
                                        return (
                                            <div key={image} style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${image})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                                        )
                                    })}
                                </AutoplaySlider>
                            </div>
                            <div className='solo-details-div' ref={soloInfoRef}>
                                {soloInfoMinimized ?
                                    <Tooltip disableInteractive={true} title='Maximizar texto'>
                                        <OpenInFull htmlColor='white' className='minimize-icon' onClick={() => { setSoloInfoMinimized(false); soloInfoRef.current.classList.toggle('minimized', false); }} />
                                    </Tooltip>
                                    :
                                    <Tooltip disableInteractive={true} title='Minimizar texto'>
                                        <CloseFullscreen htmlColor='white' className='minimize-icon' onClick={() => { setSoloInfoMinimized(true); soloInfoRef.current.classList.toggle('minimized', true) }} />
                                    </Tooltip>
                                }
                                <div className='bottom-details-text-area-test'>
                                    <p className='project-details-card-description-title'>{t('projects.projectsDetails.section_two.description_title')}</p>
                                    <p className='project-details-card-description-bottom'>
                                        {i18n.language === 'pt' ?
                                            regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: project.project_description_pt })
                                            :
                                            regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: project.project_description_en })
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default ProjectDetails