
const english = {

    "common": {
        "submit": "Submit",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "seemore": "See more",
        "back": "Back",
        "next": "Next",
        "see": "See",
        "supportProject": "Support Project",
        "name": "Name/Entity",
        "phone": "Phone",
        "message": "Message",
        "helpTitle": "How do tou want to help?",
        "loading": "Loading",
        "searching": "Searching",
        "close": "Close",
        "openinnew": "Open in new tab"
    },

    "navbar": {
        "buttons": {
            "about": "ABOUT",
            "projects": "PROJECTS",
            "results": "RESULTS",
            "news": "NEWS",
            "contacts": "CONTACTS",
            "inputplaceholder": "Type here"
        },
        "tooltips": {
            "map": "Interactive Map",
            "search": "Search on site",
            "lang": "Choose language"
        }
    },

    "footer": {
        "subscribe": "SUBSCRIBE"
    },

    "about": {
        "section_one": {
            "title": "LUGAR",
            "subtitle": "The site for social innovation in Aldeias de Montanha.",
            "description": `The Lugar Platform is the meeting point between projects in Aldeias de Montanha that need resources (financial, goods or services) and citizens, companies or institutions interested in supporting their implementation.

            Following a logic similar to crowdfunding, companies can contribute within the scope of their social responsibility programs. But we want everyone's participation, whether significant or more modest, since each project has specific needs.
            
            We believe that the opportunities for Portugal's inland territories are just beginning. And Lugar is a tool to respond to Aldeias de Montanha's challenges related to population decline. We aim to sustainably give life to innovative and transformative projects for these villages.
            
            It's so easy to participate! Choose a <1>project</1>, see the items necessary for its completion and offer the resources you have available.
            If you have a project you would like to submit, feel free to <2>contact us</2>.`,
        },
        "section_two": {
            "title": "The Lugar Platform allows you to support projects in various ways:",
            "images": {
                "img_one": "FINANCIAL",
                "img_two": "GOODS",
                "img_three": "SERVICES",
            },
            "box_one": "Supporters and Sponsorships",
            "box_two": "Projects carried out",
        },
        "section_three": {
            "title": "ALDEIAS DE MONTANHA",
            "subtitle": "Authentic nature, genuine people",
            "description": `Aldeias de Montanha is a network of 41 villages found between the Serra da Estrela Natural Park and the Serra da Gardunha Protected Landscape.
            In each one, you will find majestic landscapes, hidden gems of the purest nature and vibrant communities, guardians of their traditions and rural practices, always ready to share their stories and sympathy.
            
            Aldeias de Montanha is an innovative and creative territory where people are experimenting with new ways of living in Portugal's inland regions. In these villages, the first rural coworking spaces in Portugal were born, the <1>Espaços Cooperativa @ Aldeias de Montanha</1>. Also, they are home to <2>Queijeiras Project</2> already recognized for its innovative way of combining design, social innovation, burel (a regional wool fabric) and local production to empower the cheesemaker women of Serra da Estrela.`,
            "partners": "PARTNER MUNICIPALITIES"
        },
        "section_four": {
            "title": "ADIRAM",
            "subtitle": "Association for the Integrated Development of the Aldeias de Montanha Network",
            "description": `ADIRAM is a not-for-profit association of several local partners from the public and private sectors.
            Its mission is to contribute to developing the Serra da Estrela and Gardunha territory, with particular emphasis on its mountain villages. From a cultural, social, environmental and economic perspective, it aims to promote its sustainable, integrated, innovative and creative growth.
            ADIRAM also seeks to promote the network's brand, Aldeias de Montanha, aggregating the tourism potential of Serra da Estrela and Beira Interior Region.
            
            If you want to know more, establish a partnership or start a project in the territory of Aldeias de Montanha, contact: centrodinamizador@aldeiasdemontanha.pt`
        }
    },

    "projects": {
        "projectsDetails": {
            "section_one": {
                "description_title": "The Space"
            },
            "section_two": {
                "description_title": "The project",
                "tooltips": {
                    "projectNeeds": "This project needs",
                    "share": "Share this project",
                    "see360": "See project in 360º"
                }
            }
        },
        "help": {
            "notasks": "We are still defining all the possible supports for this project. Please come back later",
            "left-side": {
                "text": "Help this project come alive!",
            },
            "right-side": {
                "missing": "Still missing",
                "local-craft": "Local craft",
                "hover-text": "Want to know who helped?"
            },
            "dialog": {
                "title": "Who helped:",
                "nooneHelped": "Be the first to support this task"
            },
            "emailDialog": {
                "text1": "Thank you for being available to support this project.",
                "text2": "Fill in the form with your information, and we will contact you as soon as possible.",
                "text3": "If you have any questions regarding the project or how you can contribute, send us a message using this form or through our <1>contacts</1>."
            }
        }
    },

    "results": {
        "title": "Soon",
        "subtitle": "Soon we will announce here the results achieved by each project."
    },

    "news": {
        "topBar": {
            "searchPlaceholder": "Search for News",
            "themes": "Themes",
            "recent": "More Recent"
        },
        "common": {
            "noresults": "No Results",
            "back": "Back",
            "searching": "Searching",
            "shortInput": "Search Input too short, try to be more specific",
            "nonews": "There are no news to show",
            "seemore": "See more",
            "toTop": "To Top",
            "viewalltheme": "View all news from",
            "themeTitle": "Most recent news from",
            "suggested": "Suggested",
            "newsFrom": "News from"
        }
    },

    "contacts": {
        "title": "Contact Us!",
        "text": `Please send us your questions and comments using the form. 
        You can also contact us via email geral@lugaram.com`,
        "phone": "Phone",
        "address": "Address",
        "dialogLoading": "Please wait while we register your contact",
        "dialogSuccess": "Thank you for your contact.\n\nWe will get in touch as soon as possible",
        "dialogError": "There was an error while sending your request.\n\nPlease try again later, if the error persists contact us trough other means"
    },

    "search": {
        "project": "Project",
        "news": "News",
        "title": "Showing results for"
    },

    "map": {
        "text": "The geographical place where the different projects in Aldeias de Montanha are underway."
    },
    
    "formValidation": {
        "name": "is not a valid name",
        "phone": "is not a valid phone number",
        "email": "is not a valid email",
        "message": "Message is too short",
        "required": "This field is required"
    },
    "notFound": {
        "title": "Page not found",
        "subtitle": "Looks like you followed a broken link or entered a URL that does not exists on this website"
    },
    "newsletter": {
        "title": "Subscribe to our newsletter",
        "subtitle": "Type your email on the field below, and then click on subscribe",
        "button": "Subscribe",
        "response": "Thank you for subscribing to our newsletter"
    }
}

export default english