import React from 'react'
import { withTranslation } from 'react-i18next'
import Confirmation from '../Dialog/Confirmation'
import StandardButton from '../Buttons/Standard'
import axios from 'axios'

import './css/Contactos.css'

class Contacts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            phone: '',
            email: '',
            message: '',
            formErrors: { name: '', phone: '', email: '', message: '' },
            nameIsValid: false,
            phoneIsValid: false,
            emailIsValid: false,
            messageIsValid: false,
            formIsValid: false,
            submitting: false,
            openDialog: false,
            dialogFase: 1,
        }
    }

    setInitialState = () => {
        this.setState({
            name: '',
            phone: '',
            email: '',
            message: '',
            formErrors: { name: '', phone: '', email: '', message: '' },
            nameIsValid: false,
            phoneIsValid: false,
            emailIsValid: false,
            messageIsValid: false,
            formIsValid: false,
            submitting: false,
            openDialog: false,
            dialogFase: 1,
        })
    }

    handleInput(event, t) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value }, () => this.validateField(name, value, t))
    }

    validateField(fieldName, value, t) {
        let fieldValidationErrors = this.state.formErrors;
        let nameIsValid = this.state.nameIsValid;
        let phoneIsValid = this.state.phoneIsValid;
        let emailIsValid = this.state.emailIsValid;
        let messageIsValid = this.state.messageIsValid;

        switch (fieldName) {
            case 'name':
                nameIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/).test(value) && value.length > 4;
                fieldValidationErrors.name = nameIsValid ? '' : value.length > 0 ? `${value} ${t('formValidation.name')}` : `${t('formValidation.required')}`;
                break;
            case 'email':
                emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
                fieldValidationErrors.email = emailIsValid ? '' : value.length > 0 ? `${value} ${t('formValidation.email')}` : `${t('formValidation.required')}`;
                break;
            case 'phone':
                phoneIsValid = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/).test(value) && (value.length >= 9);
                fieldValidationErrors.phone = phoneIsValid ? '' : value.length > 0 ? `${value} ${t('formValidation.phone')}` : `${t('formValidation.required')}`;
                break;
            case 'message':
                messageIsValid = value.length > 10;
                fieldValidationErrors.message = messageIsValid ? '' : value.length > 0 ? `${t('formValidation.message')}` : `${t('formValidation.required')}`;
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            nameIsValid: nameIsValid,
            phoneIsValid: phoneIsValid,
            emailIsValid: emailIsValid,
            messageIsValid: messageIsValid
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.nameIsValid &&
                this.state.emailIsValid &&
                this.state.phoneIsValid &&
                this.state.messageIsValid
        })
    }

    errorClass(error) {
        return (!error ? '' : 'error')
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ openDialog: true })

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/sendcontactemail`, {
            userName: this.state.name,
            userPhone: this.state.phone,
            userEmail: this.state.email,
            message: this.state.message
        }, { headers: { key: process.env.REACT_APP_API_KEY } }).then((res) => {
            this.setState({ dialogFase: 2 })
        }, err => {
            this.setState({ dialogFase: 3 })
            console.log(err)
        })
    }

    render() {

        const { t } = this.props;

        return (
            <div className='contactos-main-div'>
                <div className='contacts-form-div'>
                    <form className='contacts-form' onSubmit={this.handleSubmit}>
                        <div className='contacts-name-phone-fields'>
                            <label className='contacts-labels'>
                                <div className='contacts-labels-title'>{t('common.name')}</div>
                                <input
                                    className={`contacts-input ${this.errorClass(this.state.formErrors.name)}`}
                                    name='name'
                                    placeholder='-'
                                    value={this.state.name}
                                    onChange={event => this.handleInput(event, t)}
                                />
                                <p style={{ marginLeft: '10px', color: 'white', textShadow: '0px 0px 10px white' }}>{this.state.formErrors['name']}</p>
                            </label>
                            <label className='contacts-labels'>
                                <div className='labels-title'>{t('common.phone')}</div>
                                <input
                                    className={`contacts-input ${this.errorClass(this.state.formErrors.phone)}`}
                                    name='phone'
                                    placeholder='-'
                                    value={this.state.phone}
                                    onChange={event => this.handleInput(event, t)}
                                />
                                <p style={{ marginLeft: '10px', color: 'white', textShadow: '0px 0px 10px white' }}>{this.state.formErrors['phone']}</p>
                            </label>
                        </div>
                        <div className='contacts-email-item-fields'>
                            <label className='contacts-email-label' htmlFor='email'>
                                <div className='labels-title'>EMAIL</div>
                                <input
                                    className={`contacts-input ${this.errorClass(this.state.formErrors.email)}`}
                                    name='email'
                                    placeholder='-'
                                    value={this.state.email}
                                    onChange={event => this.handleInput(event, t)}
                                />
                                <p style={{ marginLeft: '10px', color: 'white', textShadow: '0px 0px 10px white' }}>{this.state.formErrors['email']}</p>
                            </label>
                        </div>
                        <div className='contacts-message-field'>
                            <label className="contacts-message-label">
                                <div className="contacts-labels-title">{t('common.message')}</div>
                                <textarea rows={10} className={`contacts-email-text-area ${this.errorClass(this.state.formErrors.message)}`} name="message" placeholder="-"
                                    value={this.state.message}
                                    onChange={(event) => this.handleInput(event, t)} />
                                <p style={{ marginLeft: '10px', color: 'white', textShadow: '0px 0px 10px white' }}>{this.state.formErrors["message"]}</p>
                            </label>
                        </div>
                        <div className='contacts-button-div'>
                            <StandardButton style={{ margin: ' 0 5px' }} styleType='standard' type='submit' disabled={!this.state.formIsValid}>{t('common.submit')}</StandardButton>
                        </div>
                    </form>
                </div>
                <div className='contacts-text-div'>
                    <p className='contacts-text-div-title'>{t('contacts.title')}</p>
                    <p className='contacts-text-div-text'>{t('contacts.text')}</p>
                    <div className='contacts-text-div-bottom'>
                        <p>{t('contacts.phone')}</p>
                        <p>+351 238 310 246</p>
                    </div>
                    <div className='contacts-text-div-bottom'>
                        <p>{t('contacts.address')}</p>
                        <p>Largo Dr. Borges Pires, 6270-494 Seia</p>
                    </div>
                </div>
                <Confirmation open={this.state.openDialog} close={() => this.setInitialState()} fase={this.state.dialogFase} />
            </div>
        )
    }
}

export default withTranslation('translation')(Contacts)
