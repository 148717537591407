import React from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NewsSmallCard from '../cards/NewsSmallCard'
import Standard from '../Buttons/Standard'
import Masonry from '@mui/lab/Masonry'

import './NewsThemes.css'

function NewsThemes() {
	const [themes] = useOutletContext()

	return (
		<div className='news-themes-main-div'>
			<Masonry columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }} spacing={4}>
				{themes.filter(theme => theme.news.length > 0).map(theme => {
					return (
						<ThemeCard key={theme.id} theme={theme} />
					)
				})}
			</Masonry>
		</div>
	)
}

export default NewsThemes

const ThemeCard = ({ theme }) => {
	const { t, i18n } = useTranslation('translation')
	const navigate = useNavigate()

	const handleNavigate = () => {
		navigate(`/noticias/temas/${theme.name_pt}?id=${theme.id}`, { state: { theme } })
	}

	return (
		<div className='theme-card-main-div'>
			<div className='theme-card-top'>
				<p>{i18n.language === 'pt' ? theme.name_pt : theme.name_en}</p>
			</div>
			<p className='theme-card-title'>{`${t('news.common.themeTitle')} ${i18n.language === 'pt' ? theme.name_pt : theme.name_en}`}</p>
			<div className='theme-card-bottom'>
				{theme.news.filter(news => news.published).map((elem, index) => {
					if (index > 2) return ''
					return (
						<NewsSmallCard index key={elem.id} news={elem} />
					)
				})}
			</div>
			{theme.news.length > 3 &&
				<Standard onClick={handleNavigate} style={{ color: 'var(--light-beige)', borderColor: 'var(--light-beige)', width: '100%' }}>
					{`${t('news.common.viewalltheme')} ${i18n.language === 'pt' ? theme.name_pt : theme.name_en}`}
				</Standard>
			}
		</div>
	)
}