import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, NavLink } from 'react-router-dom';
import { fetchThemes } from '../../data/Themes';
import LupaLight from '../../assets/navbar/Lupa_light.svg'
import { ArrowRight } from '@mui/icons-material';

import './css/News.css'

const searchSuggestions = new Set([])

function News() {
    const [searchInput, setSearchInput] = useState('')
    const [showSearchHelperText, setShowSearchHelperText] = useState(false)
    const [themes, setThemes] = useState([])

    const { t } = useTranslation('translation')
    const navigate = useNavigate()

    const handleSearchInput = (value) => {
        if (value.target.value.length === 0) setShowSearchHelperText(false)
        if (new RegExp(/^[a-zA-Z\u00C0-\u00FF]*$/).test(value.target.value)) {
            setSearchInput(value.target.value)
        }
    }

    const NavigateToSearch = () => {
        if (searchInput.length > 4) {
            setShowSearchHelperText(false)
            searchSuggestions.add(searchInput)
            navigate(`/noticias/search`, { state: { search: searchInput.toLowerCase() } })
            setSearchInput('')
        } else {
            setShowSearchHelperText(true)
        }
    }

    useEffect(() => {
        fetchThemes(setThemes)
    }, [])

    return (
        <div className='news-main-container'>
            <div className='news-action-bar'>
                <div className='news-search-div'>
                    {showSearchHelperText && <p className='search-helper-text'>{t('news.common.shortInput')}</p>}
                    <div className='news-search-icon'>
                        <img alt='search' src={LupaLight} className='navbar-button-img' style={{ transform: 'scale(0.8)' }} />
                    </div>
                    <input
                        className='news-search-input'
                        type='text'
                        list='search-data-list'
                        value={searchInput}
                        maxLength={40}
                        placeholder={t('news.topBar.searchPlaceholder')}
                        onChange={handleSearchInput}
                        onKeyPress={e => { if (e.key === 'Enter') NavigateToSearch() }}
                    />
                    <datalist id='search-data-list'>
                        {Array.from(searchSuggestions).map(sug => {
                            return (
                                <option key={sug} value={sug}>{sug}</option>
                            )
                        })}
                    </datalist>
                    <div className='news-search-arrow'>
                        <ArrowRight htmlColor='white' className='news-arrow-icon' onClick={() => NavigateToSearch()} />
                    </div>
                </div>
                <NavLink to='/noticias/temas' className={({ isActive }) => isActive ? 'news-top-btn active' : 'news-top-btn'}>{t('news.topBar.themes')}</NavLink>
                <NavLink to='/noticias/lista' className={({ isActive }) => isActive ? 'news-top-btn active' : 'news-top-btn'}>{t('news.topBar.recent')}</NavLink>
            </div>
            <div className='news-content-div'>
                <Outlet context={[themes]} />
            </div>
        </div >
    )
}

export default News
