import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import regexifyString from 'regexify-string'

import { Add } from '@mui/icons-material'

import './NewsBigCard.css'
import { trimText, openInNewTab, limitTextSize } from '../../utils'

function NewsBigCard(props) {
    const navigate = useNavigate()
    const { i18n } = useTranslation('translation')
    moment.locale(i18n.language)

    const regexifyPattern = /\[\[(.*?)\]\]/gim;
    const regexifyDecorator = (match, index) => {
        const string = match.substring(2, match.length - 2)
        if (string.includes('@')) {
            return <a key={match} className='text-link' style={{ color: 'black' }} href={`mailto:${string.split('|')[0]}`}>{string.split('|')[1]}</a>
        }
        return <label key={match} className='text-link' style={{ color: 'black' }} onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</label>
    }

    return (
        <div className='news-big-card-main-div'
            onClick={() => navigate(`/noticias/${trimText(props.news.title_pt)}?id=${props.news.id}&tid=${props.news.newsThemeId}`, { state: { news: props.news } })}
            style={props.index % 2 === 0 ? { flexDirection: 'row-reverse', animationDelay: `${(0.1 * props.index)}s` } : { flexDirection: 'row', animationDelay: `${(0.1 * props.index)}s` }}
        >
            <div className='news-big-card-image'>
                <div className='news-big-card-overlay'>
                    <Add
                        htmlColor='white'
                        fontSize='large'
                        sx={props.index % 2 === 0 ?
                            { border: '1px solid white', position: 'absolute', right: '0px' } :
                            { border: '1px solid white', position: 'absolute', left: '0px' }}
                    />
                </div>
                <img alt='news big' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/news/${props.news.image}`} height='100%' width='100%' style={{ objectFit: 'cover' }} />
            </div>
            <div className='news-big-card-info'>
                <p className='news-big-card-date'>{moment(props.news.createdAt).format('DD MMMM YYYY')}</p>
                <p className='news-big-card-title'>{i18n.language === 'pt' ? props.news.title_pt : props.news.title_en}</p>
                <p className='news-big-card-location'>{props.news.location}</p>
                <p className='news-big-card-description'>
                    {i18n.language === 'pt' ?
                        regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: limitTextSize(props.news.description_pt, 150, true) })
                        :
                        regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: limitTextSize(props.news.description_en, 350, true) })
                    }
                </p>
            </div>
        </div>
    )
}

export default NewsBigCard