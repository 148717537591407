import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AwesomeSlider from 'react-awesome-slider'
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import './css/HomeSlider.css';

import { ArrowLeft, ArrowRight } from '@mui/icons-material'

import './css/Home.css'

import { fetchProjects } from '../../data/Projects';
import { trimText } from '../../utils';

const AutoplaySlider = withAutoplay(AwesomeSlider);

function Home() {

    const { t } = useTranslation('translation')

    const [projects, setProjects] = useState([])

    useEffect(() => {
        fetchProjects(filterProjects)
    }, [])

    const filterProjects = (projects) => {
        setProjects(projects.filter(proj => proj.main_page))
    }


    return (
        <div className='home-mainDiv'>
            <div className='slider-container'>
                {projects.length > 0 &&
                    <AutoplaySlider
                        fillParent={true}
                        bullets={false}
                        organicArrows={false}
                        buttonContentRight={<ArrowRight className='arrow-right' />}
                        buttonContentLeft={<ArrowLeft className='arrow-left' />}
                        play={true}
                        interval={5000}
                        cancelOnInteraction={false}
                    >
                        {projects.map((project, index) => {
                            return (
                                <div key={project.id} className='home-card'
                                    style={{
                                        backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${project.background_picture})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                >
                                    <div className='card-left-side'>
                                        <p className='card-title'>{project.title}</p>
                                        <p className='card-subtitle'>{project.address}</p>
                                        <Link className='card-button' to={`/projetos/${trimText(project.title)}?pid=${project.id}`} state={{ data: project }}>
                                            {t('common.seemore')}
                                        </Link>
                                        <p className='card-counter'>{`${index + 1}/${projects.length}`}</p>
                                    </div>
                                </div>
                            )
                        })}

                    </AutoplaySlider>
                }
            </div>
        </div>
    )
}

export default Home
