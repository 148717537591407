import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import StandardButton from '../Buttons/Standard'

import './css/NotFound.css'

function NotFound() {
    const { t } = useTranslation('translation')
    const navigate = useNavigate()

    return (
        <div className='not-found-main-div'>
            <p className='not-found-title'>{t('notFound.title')}</p>
            <p className='not-found-subtitle'>{t('notFound.subtitle')}</p>
            <StandardButton onClick={() => navigate('/')}>{t('news.common.back')}</StandardButton>
        </div>
    )
}

export default NotFound
