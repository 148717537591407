import React from 'react';
import ReactDOM from 'react-dom';
import i18n from './Lang/i18n'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App';

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<BrowserRouter>
			<Routes>
				<Route path='/*' element={<App />} />
			</Routes>
		</BrowserRouter>
	</I18nextProvider>,
	document.getElementById('root')
);


