import axios from 'axios'

let projects = []

export const getProjectFromId = async (id, setProject) => {
    if (projects.length > 0) {
        const project = projects.filter(project => project.id === parseInt(id))
        if (project[0]) {
            setProject(project[0])
            return;
        }
        setProject([])
    } else {
        await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/projects/${id}`, {
            headers: { 'key': process.env.REACT_APP_API_KEY }
        }).then(res => {
            setProject(res.data.filter(elem => elem.online))
        }, err => {
            console.log(err)
        })
    }
}


export const fetchProjects = async (setProjects) => {

    if (projects.length > 0 && setProjects) {
        setProjects(projects)
        return;
    }

    await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/projects/`, {
        headers: { 'key': process.env.REACT_APP_API_KEY }
    }).then(res => {
        if(setProjects) setProjects(res.data.filter(elem => elem.online))
        projects = res.data.filter(elem => elem.online)
    }, err => {
        console.log(err)
    })
}