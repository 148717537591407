import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Add } from '@mui/icons-material'

import './NewsSmallCard.css'
import { trimText } from '../../utils'

function NewsSmallCard(props) {
    const navigate = useNavigate()
    const { i18n } = useTranslation('translation')

    return (
        <div className='news-small-card-main-div'
            onClick={() => navigate(`/noticias/${trimText(props.news.title_pt)}?id=${props.news.id}&tid=${props.news.newsThemeId}`, { state: { news: props.news } })}
            style={props.index % 2 === 0 ? { flexDirection: 'row-reverse' } : { flexDirection: 'row' }}
        >
            <div className='news-small-card-image'>
                <div className='news-small-card-overlay'>
                    <Add
                        htmlColor='white'
                        fontSize='large'
                        sx={props.index % 2 === 0 ?
                            { border: '1px solid white', position: 'absolute', right: '0px' } :
                            { border: '1px solid white', position: 'absolute', left: '0px' }}
                    />
                </div>
                <img alt='news small'
                    src={`${process.env.REACT_APP_SERVER_URL}/resources/images/news/${props.news.image}`}
                    height='100%'
                    width='100%'
                    style={{ objectFit: 'cover' }}
                />
            </div>
            <div className='news-small-card-info'>
                <p className='news-small-card-date'>{moment(props.news.createdAt).format('DD MMMM YYYY')}</p>
                <p className='news-small-card-title'>{i18n.language === 'pt' ? props.news.title_pt : props.news.title_en}</p>
                <p className='news-small-card-location'>{props.news.location}</p>
            </div>
        </div>
    )
}

export default NewsSmallCard