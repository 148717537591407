import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import portugues from './pt'
import english from './en'

i18n.use(LanguageDetector).init({
    resources: {
        fallbackLng: 'pt',
        Lng: 'pt',
        debug: true,
        ns: ['translation'],
        defaultNS: 'translation',
        keySeparator: true,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            wait: true
        },
        pt: {
            translation: portugues
        },
        en: {
            translation: english
        }
    }
})

export default i18n;