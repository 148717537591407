import React, { useState, useRef, useEffect } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useNavigate } from 'react-router-dom'
import useDrag from '../horizontalScroll/useDrag';
import { RightArrow, LeftArrow } from '../horizontalScroll/arrows';
import './css/Projects.css'

import { fetchProjects } from '../../data/Projects';
import Card from '../projects/Card';
import { trimText } from '../../utils';


function Projects() {
    const { dragStart, dragStop, dragging, handleDrag } = useDrag();
    const [cardsWidthFullWindow, setCardsWidthFullWindow] = useState(false)
    const [data, setData] = useState([])
    const navigate = useNavigate();

    const scrollContainerRef = useRef()
    const cardContainerRef = useRef()

    const handleClick = (project) => {
        if (dragging) {
            return false;
        }
        navigate(`${trimText(project.title)}?pid=${project.id}`, { state: { data: project } })
    }

    useEffect(() => {
        fetchProjects(setData)
    }, [])

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            setCardsWidthFullWindow(450 * data.length > entries[0].contentRect.width)
        })

        const container = cardContainerRef.current;
        if (container) observer.observe(container);

        return () => {
            if (container) observer.unobserve(container)
        }

    }, [cardContainerRef, data])

    return (
        <>
            <div
                className='projects-main-div'
                onMouseLeave={dragStop}
                style={!cardsWidthFullWindow ? { display: 'flex', justifyContent: 'center' } : {}}
                ref={cardContainerRef}
            >
                <ScrollMenu
                    apiRef={scrollContainerRef}
                    LeftArrow={LeftArrow}
                    RightArrow={RightArrow}
                    itemClassName='scroll-container-projects-item'
                    onMouseDown={() => dragStart}
                    onMouseUp={() => dragStop}
                    onMouseMove={handleDrag}
                >
                    {data.map((project, index) => {
                        return <Card length={data.length} key={project.id} index={index + 1} handleClick={() => handleClick(project)} project={project} />
                    })}
                </ScrollMenu>
            </div>
            <div className='projects-main-div-mobile'>
                {data.map((project, index) => {
                    return (<Card key={project.id} handleClick={() => handleClick(project)} project={project} />)
                })}
            </div>
        </>
    )
}


export default Projects
