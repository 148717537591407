import axios from 'axios'

let themes = []

export const getEpisodesFromTheme = async (id, setSuggested) => {
    if (themes.length > 0) {
        const theme = themes.filter(theme => theme.id === parseInt(id))
        if (theme[0]) {
            const filteredNews = theme[0].news.filter(news => news.published)
            setSuggested(filteredNews)
            return;
        }
        setSuggested([])
    }
}

export const getSuggestedNews = async (themeId, newsId, setSuggested) => {
    if (themes.length > 0) {
        const theme = themes.filter(theme => theme.id === parseInt(themeId))[0]

        if (!theme) {
            setSuggested(null)
            return;
        }

        if (theme.news.length > 0) {
            const filteredNews = theme.news.filter(news => news.published && news.id !== newsId)
            if (filteredNews.length === 0) {
                const otherThemes = themes.filter(theme => theme.id !== themeId)
                setSuggested([].concat(...otherThemes.map(theme => theme.news)).filter(news => news.published))
                return;
            }
            setSuggested(filteredNews)
            return;
        }
        const otherThemes = themes.filter(theme => theme.id !== themeId)
        setSuggested([].concat(...otherThemes.map(theme => theme.news)).filter(news => news.published))
    }
}


export const fetchThemes = async (setThemes) => {
    await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/newsTheme/`, {
        headers: { 'key': process.env.REACT_APP_API_KEY }
    }).then(res => {
        if (setThemes) setThemes(res.data.filter(theme => !theme.hide))
        themes = res.data.filter(theme => !theme.hide)
    }, err => {
        console.log(err)
    })
}