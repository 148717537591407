import axios from 'axios'

export const fetchNews = async (id, setNews) => {
    await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/news/${id}`, {
        headers: { 'key': process.env.REACT_APP_API_KEY }
    }).then(res => {
        if (res.data.published) {
            setNews(res.data)
        } else {
            setNews(null)
        }
    }, err => {
        console.log(err)
    })
}