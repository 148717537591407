import React, { useContext, useRef, useEffect } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { IconButton } from '@mui/material';
import useWheel from './useWheel';

import './arrows.css'

export function LeftArrow() {
    const { sideScroll } = useWheel();
    const { scrollContainer } = useContext(VisibilityContext)
    const arrowRef = useRef(null)
    const firstCard = document.querySelector(".scroll-container-projects-item:first-child")

    const observer = new IntersectionObserver(entries => {
        if (arrowRef.current) {
            arrowRef.current.classList.toggle('show', !entries[0].isIntersecting)
        }
    }, { threshold: 0.8, rootMargin: '300px 0px' })


    useEffect(() => {
        if (firstCard && arrowRef) observer.observe(firstCard);
        return () => {
            if (firstCard) observer.unobserve(firstCard)
        }
    }, [arrowRef, firstCard])


    return (
        <IconButton
            ref={arrowRef}
            className='project-arrows-left'
            disabled={false}
            onClick={() => sideScroll(scrollContainer.current, 'left', 10, 500, 10)}
        >
            <ArrowLeft />
        </IconButton>
    )
}

export function RightArrow() {
    const { sideScroll } = useWheel();
    const { scrollContainer } = useContext(VisibilityContext)
    const arrowRef = useRef(null)
    const lastCard = document.querySelector(".scroll-container-projects-item:last-child")

    const observer = new IntersectionObserver(entries => {
        if (arrowRef.current) {
            arrowRef.current.classList.toggle('show', !entries[0].isIntersecting)
        }
    }, { threshold: 0.8, rootMargin: '300px 0px' })


    useEffect(() => {
        if (lastCard && arrowRef) observer.observe(lastCard);
        return () => {
            if (lastCard) observer.unobserve(lastCard)
        }
    }, [arrowRef, lastCard])

    return (
        <IconButton
            ref={arrowRef}
            className='project-arrows-right'
            disabled={false}
            onClick={() => sideScroll(scrollContainer.current, 'right', 10, 500, 10)}
        >
            <ArrowRight />
        </IconButton>
    )
}