import axios from 'axios'

export const trimText = (string) => {
    return string.replace(/[;:,!*`?&/\\"<>|'\s]/g, '').toLowerCase()
}

export function debounce(fn, ms) {
    let timer;
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn(...args)
        }, ms)
    };
}

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null
}

export const limitTextSize = (string, maxChar, insertDots) => {
    return string.slice(0, maxChar) + (((string.length > maxChar) && insertDots) ? '...' : '');
}

export const addView = async (entity, entity_id) => {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/stats/add_view`, { entity, entity_id },  { headers: { key: process.env.REACT_APP_API_KEY } })
}

export const addShare = async (entity, entity_id) => {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/stats/add_share`, { entity, entity_id },  { headers: { key: process.env.REACT_APP_API_KEY } })
}

