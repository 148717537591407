import './App.css';
import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import Home from './components/pages/Home'
import About from './components/pages/About'
import Contacts from './components/pages/Contacts'
import Map from './components/pages/Map'
import News from './components/pages/News'
import Projects from './components/pages/Projects'
import ProjectDetails from './components/pages/ProjectDetails';
import Results from './components/pages/Results'
import Search from './components/pages/Search'
import ApoiarPage from './components/pages/ApoiarPage'
import NotFound from './components/pages/NotFound'
import LoadingPage from './components/pages/LoadingPage';

import NewsInfo from './components/NewsComponents/NewsInfo';
import NewsList from './components/NewsComponents/NewsList';
import NewsSlider from './components/NewsComponents/NewsSlider';
import NewsThemes from './components/NewsComponents/NewsThemes';
import NewsSearch from './components/NewsComponents/NewsSearch';
import NewsTheme from './components/NewsComponents/NewsTheme';

import { fetchThemes } from './data/Themes'
import { fetchProjects } from './data/Projects'
import usePageTracking from './useTracking';

function App() {
	const [ready, setReady] = useState(false)
	// usePageTracking();

	useEffect(() => {
		fetchProjects().then(() => {
			fetchThemes().then(() => {
				setReady(true)
			})
		})
	}, [])

	return (
		<div>
			{!ready ?
				<LoadingPage />
				:
				<>
					<Navbar />
					<Routes>
						<Route path='/' element={<Home />} />
						<Route end path='sobre' element={<About />} />
						<Route path='projetos' element={<Projects />} />
						<Route path='projetos/:name' element={<ProjectDetails />} />
						<Route path='projetos/:name/apoiar' element={<ApoiarPage />} />
						<Route path='noticias' element={<News />}>
							<Route index element={<NewsSlider />} />
							<Route path=':newsId' element={<NewsInfo />} />
							<Route path='lista' element={<NewsList />} />
							<Route path='temas' element={<NewsThemes />} />
							<Route path='temas/:tema' element={<NewsTheme />} />
							<Route path='search' element={<NewsSearch />} />
						</Route>
						<Route path='contactos' element={<Contacts />} />
						<Route path='mapa' element={<Map />} />
						<Route path='resultados' element={<Results />} />
						<Route path='search/:string' element={<Search />} />
						<Route path='*' element={<NotFound />} />
					</Routes>
					<Footer />
				</>
			}
		</div>
	);
}

export default App;
