import React, { useState, useEffect, useRef } from 'react'
import NewsBigCard from '../cards/NewsBigCard'
import { useTranslation } from 'react-i18next';
import axios from 'axios'

import { LoadingGrid } from '../Loading/LoadingGrid';
import StandardButton from '../Buttons/Standard'

import './NewsList.css'

function NewsList() {
	const { t } = useTranslation('translation')

	const [data, setData] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [count, setCount] = useState(0)
	const containerRef = useRef(null)

	const fetchData = async () => {
		setIsLoading(true)

		await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/news/getAll/${data.length}`, {
			headers: { 'key': process.env.REACT_APP_API_KEY }
		}).then(res => {
			setCount(res.data.count)
			setIsLoading(false)
			setData(prev => [...prev, ...res.data.rows])
		}, err => {
			setIsLoading(false)
			console.log(err)
		})
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<div className='news-list-main-div' ref={containerRef}>
			{data?.length > 0 ?
				<>
					<div className='news-list-content-div' >
						{data.map((elem, index) => {
							return (
								<NewsBigCard news={elem} index={index} key={index} />
							)
						})}
					</div>
					{isLoading ? <LoadingGrid /> :
						<div className='news-list-view-more-btn-div'>
							{data.length < count &&
								<StandardButton
									disabled={isLoading}
									styleType='round'
									style={{ borderColor: 'var(--dark-beige)', color: 'var(--dark-beige)' }}
									onClick={() => fetchData()}
								>
									{t('news.common.seemore')}
								</StandardButton>
							}
							{containerRef.current.scrollTop > 300 &&
								<StandardButton
									disabled={isLoading}
									styleType='round'
									style={{ borderColor: 'var(--dark-beige)', color: 'var(--dark-beige)' }}
									onClick={() => containerRef.current.scrollTop = 0}
								>
									{t('news.common.toTop')}
								</StandardButton>
							}
						</div>
					}
				</>
				:
				<div>
					<p className='no-results-text'>{t('news.common.nonews')}</p>
				</div>
			}
		</div>
	)
}

export default NewsList