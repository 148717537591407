import React from 'react'
import logo from '../../assets/navbar/LUGAR.svg'

import './css/loadingPage.css'

function LoadingPage() {
    return (
        <div className='loading-page-main-div'>
            <img alt='lugar logo' src={logo} className='loading-page-logo'/>
            <div className='loading-page-text'>Loading</div>
        </div>
    )
}

export default LoadingPage