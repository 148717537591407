
const portugues = {
    "common": {
        "submit": "Submter",
        "cancel": "Cancelar",
        "confirm": "Confirmar",
        "seemore": "Ver Mais",
        "back": "Retroceder",
        "next": "Seguinte",
        "see": "Ver",
        "supportProject": "Apoiar Projeto",
        "name": "Nome/Entidade",
        "phone": "Telemóvel",
        "message": "Mensagem",
        "helpTitle": "Como pretende ajudar?",
        "loading": "A carregar",
        "searching": "A procurar",
        "close": "Fechar",
        "openinnew": "Abrir em novo separador"
    },

    "navbar": {
        "buttons": {
            "about": "SOBRE",
            "projects": "PROJETOS",
            "results": "RESULTADOS",
            "news": "NOTICIAS",
            "contacts": "CONTACTOS",
            "inputplaceholder": "Escreva aqui"
        },
        "tooltips": {
            "map": "Mapa interativo",
            "search": "Procura no site",
            "lang": "Escolher Lingua"
        }
    },
    "footer": {
        "subscribe": "SUBSCREVER"
    },

    "about": {
        "section_one": {
            "title": "LUGAR",
            "subtitle": "O sítio da inovação social nas Aldeias de Montanha",
            "description": `A plataforma Lugar é o ponto de encontro entre projetos nas Aldeias de Montanha que precisam de apoio (financeiro, bens ou serviços) e cidadãos, empresas ou instituições interessadas em apoiar a sua concretização.

            Seguindo uma lógica semelhante ao crowdfunding, as empresas podem contribuir no âmbito dos seus programas de responsabilidade social. Mas queremos a participação de todos, seja avultada ou mais modesta, já que cada projeto tem necessidades muito específicas.
            
            Acreditamos que as oportunidades dos territórios do interior de Portugal estão agora apenas a começar, e o Lugar é uma ferramenta para responder aos desafios das Aldeias de Montanha relacionados com a desertificação populacional. Dando vida, de forma sustentável, a projetos inovadores e transformadores para as Aldeias.
            
            É muito fácil participar. Escolha um <1>projeto</1>, veja os items necessários à sua concretização e ofereça os recursos que tem disponíveis.
            Se tiver um projeto que gostaria de submeter, <2>contacte-nos</2>.`,
        },
        "section_two": {
            "title": "A PLATAFORMA LUGAR PERMITE O APOIO:",
            "images": {
                "img_one": "FINANCEIRO",
                "img_two": "MATERIAL",
                "img_three": "SERVIÇOS",
            },
            "box_one": "Apoiantes e Patrocínios",
            "box_two": "Projetos Realizados",
        },
        "section_three": {
            "title": "ALDEIAS DE MONTANHA",
            "subtitle": "Natureza autêntica, pessoas genuínas",
            "description": `As Aldeias de Montanha são uma rede de 41 aldeias localizadas entre o Parque Natural da Serra da Estrela e a Paisagem Protegida da Serra da Gardunha. 
            Em cada uma encontra paisagens majestosas, recantos da natureza mais pura e também comunidades vibrantes, guardiãs das suas tradições e práticas rurais, sempre prontas a partilhar as suas histórias e simpatia.
            
            As Aldeias de Montanha afirmam-se como território inovador e criativo, onde se experimentam novas formas de viver o interior de Portugal. Nestas aldeias nasceram os primeiros espaços de coworking em espaço rural em Portugal, os Espaços <1>Cooperativa @ Aldeias de Montanha</1> e também o Projeto Queijeiras, já reconhecido pela forma inovadora com uniu design, inovação social, burel e produção local para capacitar e empoderar as <2>Queijeiras</2> da Serra da Estrela.`,
            "partners": "MUNICÍPIOS PARCEIROS"
        },
        "section_four": {
            "title": "ADIRAM",
            "subtitle": "Associação de Desenvolvimento Integrado da Rede Aldeias de Montanha",
            "description": `A ADIRAM é uma associação de diversos parceiros locais, do setor público e privado e sem fins lucrativos.
            Tem como missão contribuir para o desenvolvimento do território das serras da Estrela e da Gardunha, com especial ênfase nas suas Aldeias de Montanha, numa perspetiva cultural, social, ambiental e económica, e fomentando o seu crescimento sustentável, integrado, inovador e criativo.
            A ADIRAM tem também o objetivo de promover a marca Rede de Aldeias de Montanha como agregadora do potencial turístico da Região da Serra da Estrela e Beira Interior.
            
            Se quiser saber mais, estabelecer uma parceria ou iniciar um projeto no território das Aldeias de Montanha, contacte: centrodinamizador@aldeiasdemontanha.pt`
        }
    },

    "projects": {
        "projectsDetails": {
            "section_one": {
                "description_title": "O espaço"
            },
            "section_two": {
                "description_title": "o projeto",
                "tooltips": {
                    "projectNeeds": "Este projeto necessita de",
                    "share": "Partilhe este projeto",
                    "see360": "Ver projeto em 360º"
                }
            }
        },
        "help": {
            "notasks": "Ainda estamos a definir as ajudas para este projeto, por favor volte mais tarde",
            "left-side": {
                "text": "Ajude a realizar este projeto!",
            },
            "right-side": {
                "missing": "Ainda faltam",
                "local-craft": "Artesanato Local",
                "hover-text": "Quer saber quem já ajudou?"
            },
            "dialog": {
                "title": "Quem já ajudou:",
                "nooneHelped": "Seja o primeiro a ajudar esta tarefa"
            },
            "emailDialog": {
                "text1": "Obrigado por se disponibilizar para apoiar este projeto.",
                "text2": "Preencha o formulário com os seus dados e entraremos em contacto consigo o mais breve possível.",
                "text3": "Se tiver alguma dúvida sobre o projeto ou como pode contribuir, envie-nos mensagem através deste formulário ou através dos nossos <1>contactos</1>."
            }
        }
    },

    "results": {
        "title": "Brevemente",
        "subtitle": "Em breve divulgaremos aqui todos os resultados alcançados por cada projeto."
    },

    "news": {
        "topBar": {
            "searchPlaceholder": "Pesquisa de Noticias",
            "themes": "Temas",
            "recent": "Mais recentes"
        },
        "common": {
            "noresults": "Sem resultados",
            "back": "Voltar",
            "searching": "A procurar",
            "shortInput": "Input de procura muito curto, tente ser mais especifico",
            "nonews": "Não existem noticias para apresentar",
            "seemore": "Ver mais",
            "toTop": "Para o Topo",
            "viewalltheme": "Ver todas as noticias de",
            "themeTitle": "Mais recentes noticias sobre",
            "suggested": "Sugeridos",
            "newsFrom": "Noticias sobre"
        }
    },

    "contacts": {
        "title": "Contacte-nos!",
        "text": `Envie-nos as suas dúvidas e comentários através do formulário.
            Também pode contactar-nos através do email geral@lugaram.com`,
        "phone": "Telefone",
        "address": "Morada",
        "dialogLoading": "Por favor aguarde enquanto registamos o seu contacto",
        "dialogSuccess": "Obrigado pelo seu contacto.\n\nEntraremos em contacto consigo o mais rápido possível",
        "dialogError": "Ocorreu um erro enquanto registávamos o seu contacto.\n\nPor favor tente mais tarde, se o erro persistir contacte-nos por outros meios"
    },

    "search": {
        "project": "Projeto",
        "news": "Noticia",
        "title": "A apresentar resultados para"
    },

    "map": {
        "text": "O Lugar geográfico onde estão em curso os vários projetos nas Aldeias de Montanhas."
    },

    "formValidation": {
        "name": "não é um nome válido",
        "phone": "não é um Numero de Telemóvel válido",
        "email": "não é um email válido",
        "message": "Mensagem demasiado curta",
        "required": "Este campo é obrigatório"
    },
    "notFound": {
        "title": "Pagina não existe",
        "subtitle": "Parece que seguiu um link inválido ou inseriu num URL que não existe neste website"
    },
    "newsletter": {
        "title": "Subscreva à nossa newsletter",
        "subtitle": "Indique o seu email no campo a baixo e em seguida clique em subscrever",
        "button": "Subscrever",
        "response": "Obrigado pela sua subscrição à nossa newsletter"
    }
}

export default portugues