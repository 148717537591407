import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'

import { useLocation, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import AwesomeSlider from 'react-awesome-slider'
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { ArrowCircleUpOutlined as ArrowUp } from '@mui/icons-material'

// import { ArrowLeft, ArrowRight } from '@mui/icons-material'


import adiram1 from '../../assets/about/adiram_1.jpg'
import adiram2 from '../../assets/about/adiram_2.jpg'
import adiram3 from '../../assets/about/adiram_3.jpg'

import aldeias1 from '../../assets/about/aldeias_1.jpg'
import aldeias2 from '../../assets/about/aldeias_2.jpg'
import aldeias3 from '../../assets/about/aldeias_3.jpg'

import lugar1 from '../../assets/about/lugar_1.jpg'
import lugar2 from '../../assets/about/lugar_2.jpg'
import lugar3 from '../../assets/about/lugar_3.jpg'


import FBIcon from '../../assets/footer/FB.png'
import INSTAIcon from '../../assets/footer/INSTA.png'
import iconPlaceholder from '../../assets/icons/logo_placeholder_1.png'

import './css/HomeSlider.css';
import './css/About.css'

const AutoplaySlider = withAutoplay(AwesomeSlider);

const getRandomMilliseconds = (start, end) => {
    return Math.floor(Math.random() * (end - start)) + start;
}

const openLinkOnNewWindow = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null;
}

function About() {
    const [categories, setCategories] = useState([])
    const { t, i18n } = useTranslation('translation')
    const location = useLocation()

    const scrollBtnRef = useRef(null)

    const { y } = location.state

    useEffect(() => {
        window.scrollTo({ top: y, behavior: 'smooth' })
    }, [y])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/categories`, { headers: { key: process.env.REACT_APP_API_KEY } })
            .then(res => {
                setCategories(res.data.filter(cat => !cat.hide))
            }, err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleTopBarScroll)
        return () => window.removeEventListener('scroll', handleTopBarScroll)
    }, [])

    const handleTopBarScroll = () => {
        if (window.scrollY > 400) {
            scrollBtnRef.current.classList.toggle('show', true)
        } else {
            scrollBtnRef.current.classList.toggle('show', false)
        }
    }

    return (
        <div className='about-mainDiv'>
            <div className='scroll-up-btn' ref={scrollBtnRef} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <ArrowUp htmlColor='white' fontSize='large' sx={{ fontSize: '3rem'}} />
            </div>
            <div className='lugar-div'>
                <div className='lugar-slider'>
                    <AutoplaySlider
                        fillParent={true}
                        bullets={false}
                        organicArrows={false}
                        // buttonContentRight={<ArrowRight className='arrow-right' />}
                        // buttonContentLeft={<ArrowLeft className='arrow-left' />}
                        play={true}
                        interval={getRandomMilliseconds(5000, 6000)}
                        cancelOnInteraction={false}
                    >
                        <div style={{ backgroundImage: `url(${lugar1})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                        <div style={{ backgroundImage: `url(${lugar2})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                        <div style={{ backgroundImage: `url(${lugar3})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>

                    </AutoplaySlider>
                    <div className='about-lugar-left-side'>
                        <p className='lugar-card-title'>{t('about.section_one.title')}</p>
                        <p className='lugar-card-subtitle'>{t('about.section_one.subtitle')}</p>
                        <p className='lugar-card-description' style={{ /* maxHeight: '300px' */ }}>
                            <Trans i18nKey="about.section_one.description" >
                                {t('about.section_one.description')}
                                <Link className='inline-link' style={{ color: 'white' }} to='/projetos'>link</Link>
                                <Link className='inline-link' style={{ color: 'white' }} to='/contactos'>link</Link>
                            </Trans>
                        </p>

                    </div>
                </div>
                <div className='lugar-info'>
                    <p className='lugar-info-title'>{t('about.section_two.title')}</p>
                    <div className='lugar-info-middle'>
                        {categories.length > 0 &&
                            categories.map(category => {
                                return (
                                    <div key={category.id}>
                                        <p>{i18n.language === 'pt' ? category.name_pt : category.name_en}</p>
                                        <img alt={category.name_en} src={category.icon ? `${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${category.icon.icon}` : iconPlaceholder} className='lugar-info-middle-icon' />
                                    </div>
                                )
                            })}
                    </div>
                    {/* <div className='lugar-info-bottom'>
                        <div className='lugar-info-bottom-div'>
                            <p className='lugar-info-bottom-div-number'>500</p>
                            <p className='lugar-info-bottom-div-text'>{t('about.section_two.box_one')}</p>
                        </div>
                        <div className='lugar-info-bottom-div'>
                            <p className='lugar-info-bottom-div-number'>50</p>
                            <p className='lugar-info-bottom-div-text'>{t('about.section_two.box_two')}</p>
                        </div>
                    </div> */}
                </div>

            </div>
            <div className='am-div'>
                <div style={{ height: '100%' }}>
                    <AutoplaySlider
                        fillParent={true}
                        bullets={false}
                        organicArrows={false}
                        // buttonContentRight={<ArrowRight className='arrow-right' />}
                        // buttonContentLeft={<ArrowLeft className='arrow-left' />}
                        play={true}
                        interval={getRandomMilliseconds(5000, 6000)}
                        cancelOnInteraction={true}
                    >
                        <div style={{ backgroundImage: `url(${aldeias1})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                        <div style={{ backgroundImage: `url(${aldeias2})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                        <div style={{ backgroundImage: `url(${aldeias3})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>

                    </AutoplaySlider>
                    <div className='about-am-right-side'>
                        <p className='lugar-card-title'>{t('about.section_three.title')}</p>
                        <p className='lugar-card-subtitle'>{t('about.section_three.subtitle')}</p>
                        <p className='lugar-card-description' style={{ height: '100%', marginBottom: '25px' }}>
                            <Trans i18nKey="about.section_three.description" >
                                {t('about.section_three.description')}
                                <a className='inline-link' target='_blank' rel="noreferrer" href='https://coworkaldeiasdemontanha.pt' style={{ color: 'white' }}>link</a>
                                <a className='inline-link' target='_blank' rel="noreferrer" href='http://www.queijeiras.pt' style={{ color: 'white' }}>link</a>
                            </Trans>
                        </p>
                        <div className='about-am-right-side-locations'>
                            <p style={{ color: 'white', fontSize: '20px', fontWeight: '600', textTransform: 'uppercase' }}>{t('about.section_three.partners')}</p>
                            <div className='about-am-partners'>
                                <p>| Celorico da Beira</p>
                                <p>| Fornos de Algodres</p>
                                <p>| Fundão</p>
                                <p>| Covilhã</p>
                                <p>| Gouveia</p>
                                <p>| Guarda</p>
                                <p>| Manteigas</p>
                                <p>| Oliveira do hospital</p>
                                <p>| Seia</p>
                            </div>
                        </div>
                        <div className='about-am-right-side-social'>
                            <img onClick={() => openLinkOnNewWindow('https://www.facebook.com/aldeiasmontanha')} alt='facebook' src={FBIcon} />
                            <img onClick={() => openLinkOnNewWindow('https://www.instagram.com/aldeiasdemontanha/')} alt='instagram' src={INSTAIcon} />
                            <p onClick={() => openLinkOnNewWindow('https://www.aldeiasdemontanha.pt')}>www.aldeiasdemontanha.pt</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='adiram-div'>
                <div style={{ height: '100%' }}>
                    <AutoplaySlider
                        fillParent={true}
                        bullets={false}
                        organicArrows={false}
                        // buttonContentRight={<ArrowRight className='arrow-right' />}
                        // buttonContentLeft={<ArrowLeft className='arrow-left' />}
                        play={true}
                        interval={getRandomMilliseconds(5000, 6000)}
                        cancelOnInteraction={true}
                    >
                        <div style={{ backgroundImage: `url(${adiram1})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                        <div style={{ backgroundImage: `url(${adiram2})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                        <div style={{ backgroundImage: `url(${adiram3})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>

                    </AutoplaySlider>
                    <div className='about-adiram-left-side'>
                        <p className='lugar-card-title'>{t('about.section_four.title')}</p>
                        <p className='lugar-card-subtitle'>{t('about.section_four.subtitle')}</p>
                        <p className='lugar-card-description' style={{ maxHeight: '400px' }}>{t('about.section_four.description')}</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default About
