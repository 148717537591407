import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getProjectFromId } from '../../data/Projects';

import Card from '../apoio/Card';
import ApoioDialog from '../apoio/ApoioDialog';

import './css/Apoiar.css'
import EmailDialog from '../apoio/EmailDialog';

function ApoiarPage() {

    const [openDialog, setOpenDialog] = useState(false)
    const [openEmailDialog, setOpenEmailDialog] = useState(false)
    const [dialogData, setDialogData] = useState(null)
    const [project, setProject] = useState()

    const urlParams = new URLSearchParams(window.location.search)

    const navigate = useNavigate()
    const { state } = useLocation()
    const { t } = useTranslation('translation')

    useEffect(() => {
        if (state) {
            setProject(state.data)
        } else {
            if (urlParams.get('pid')) {
                getProjectFromId(urlParams.get('pid'), setProject)
            }
            navigate('/projetos')
        }
    }, [state])

    const setDataAndOpenDialog = (data) => {
        setDialogData(data);
        setOpenDialog(true)
    }

    return (
        <>
            {project &&
                <div className='apoiar-main-div'>
                    <div className='apoiar-left-div' style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${project.background_picture})` }}>
                        <div className='apoiar-left-black-div'>
                            <p className='apoiar-left-text'>{t('projects.help.left-side.text')}</p>
                            {project.tasks.length > 0 &&
                                <div className='apoiar-left-button' onClick={() => setOpenEmailDialog(true)}>{t('common.supportProject')}</div>
                            }
                        </div>
                    </div>
                    <div className='apoiar-right-div'>
                        {project.tasks.filter(task => task.online).length > 0 ?
                            project.tasks?.filter(task => task.online).map(task => {
                                return (
                                    <Card key={task.id} data={task} onClick={() => setDataAndOpenDialog(task)} />
                                )
                            })
                            :
                            <div className='apoio-page-no-tasks'>
                                <p>{t('projects.help.notasks')}</p>
                            </div>
                        }
                    </div>
                    <ApoioDialog
                        open={openDialog}
                        close={() => setOpenDialog(false)}
                        data={dialogData}
                        allTasks={project.tasks}
                        project={project}
                    />
                    <EmailDialog
                        open={openEmailDialog}
                        close={() => setOpenEmailDialog(false)}
                        allTasks={project.tasks}
                        selectedTask={project.tasks[0]}
                    />

                </div>
            }
        </>
    )
}

export default ApoiarPage
