import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import SearchCards from '../cards/SearchCards'
import { LoadingGrid } from '../Loading/LoadingGrid'
import { useTranslation } from 'react-i18next'
import StandardButton from '../Buttons/Standard'

import './css/Search.css'

function Search() {
    const [projects, setProjects] = useState([])
    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation('translation')
    const navigate = useNavigate()

    let { string } = useParams();

    useEffect(() => {

        if(new RegExp(/\W/gm).test(string) || string.length === 0) {
            navigate('/')
            return;
        }
    
        setLoading(true)
        const decodedString = decodeURIComponent(string)

        const fetchData = async () => {
            await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/search/${decodedString}`,
                { headers: { 'key': process.env.REACT_APP_API_KEY } }
            ).then(res => { 
                setProjects(res.data.projects)
                setNews(res.data.news)
                setLoading(false)
            }, err => {
                console.log(err)
                setLoading(false)
            })
        }

        fetchData()
    }, [string])

    return (
        <div className='search-main-div'>
            <p className='search-title'>{`${t('search.title')} ${string}`}</p>
            <div className='search-results-div'>
                {loading && <LoadingGrid label={t('common.searching')} />}
                {projects.length === 0 && news.length === 0 ?
                    <div className='no-results-div'>
                        <p className='no-results-text'>{t('news.common.noresults')}</p>
                        <StandardButton
                            style={{ color: 'var(--dark-beige)', borderColor: 'var(--dark-beige)' }}
                            onClick={() => navigate(-1)}
                        >
                            {t('news.common.back')}
                        </StandardButton>
                    </div>
                    :
                    <>
                        {projects.map(project => <SearchCards key={project.title} type='project' data={project} />)}
                        {news.map(news => <SearchCards key={news.id} type='news' data={news} />)}
                    </>
                }
            </div>
        </div>
    )
}

export default Search
